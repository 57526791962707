import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  constructor(private route: Router) { }
  userInfo: any=[]
  imageUrl=environment.imageUrl

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '')
  }







  // ngAfterViewInit() {

  // }

  // referral(): void {
  //   this.dialog.open(ReferralComponent, {
  //     panelClass: ['cmn_modal', 'modal_lg'],
  //     width: '875px',
  //   });
  // }


  // sendInvite(): void {
  //   this.dialog.open(SendInvitesComponent, {
  //     panelClass: ['cmn_modal', 'modal_md'],
  //     width: '570px',
  //   });
  // }


  logOut() {
    sessionStorage.clear()
    localStorage.clear()
    this.route.navigate(['/login'])
  }

}
