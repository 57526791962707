import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  geturl: string = '';
  constructor(private cdRef: ChangeDetectorRef, private router: Router){}


  changeurl(url: string) {
    this.geturl = url;
    this.cdRef.detectChanges();
  }

  checkVisited() {
    $('body').toggleClass('sidebarOpen');
  }

}
