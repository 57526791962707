<div class="layout-wrapper position-relative">
    <div class="mainContent vh-100">
        <div class="mainContentInner cardOuterPadding h-100">
            <!-- Header layout start -->
            <div class="layoutHeader d-flex align-items-center justify-content-between pb-4">
                <div class="headerLeft d-flex align-items-center">
                    <div class="cursor_pointer" [routerLink]="['/members']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="7.125" height="12.806"
                            viewBox="0 0 7.125 12.806">
                            <g id="_4782742811535351484" data-name="4782742811535351484"
                                transform="translate(38.233 -6.15) rotate(90)">
                                <path id="Path_25" data-name="Path 25" d="M18.58,33.527a.435.435,0,0,0-.615,0l-5.406,5.417L7.142,33.527a.435.435,0,0,0-.615.615l5.714,5.714a.425.425,0,0,0,.307.127.443.443,0,0,0,.307-.127l5.714-5.714a.426.426,0,0,0,.011-.615Z" transform="translate(0 -2)" fill="#4d4e58" stroke="#4d4e58" stroke-width="0.5" />
                            </g>
                        </svg>
                        <svg class="ms-4" xmlns="http://www.w3.org/2000/svg" width="1" height="12" viewBox="0 0 1 12">
                            <line id="Line_15" data-name="Line 15" y2="12" transform="translate(0.5)" fill="none" stroke="#ebebeb" stroke-width="1" />
                        </svg>
                    </div>
                    <div class="dropdown dropdown-center cursor_pointer">
                        <div class="drodownInner d-flex align-items-center arrowRotate" id="userlist"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <!-- <img  class="wh41 rounded-pill fit_img" src=""> -->
                            <button class="btn btn-primary commonBtn rounded-pill primaryBg wh41 d-inline-flex align-items-center justify-content-center" type="button">
                                <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="user_svg_icon" transform="translate(-267.36 -127)">
                                            <rect id="Rectangle_6390" data-name="Rectangle 6390" width="18" height="18" transform="translate(267.36 127)" fill="#fff" opacity="0" />
                                            <g id="Group_1168" data-name="Group 1168" transform="translate(0.86 1)">
                                                <path id="Path_1261" data-name="Path 1261" d="M-4018.874-707.816a50.714,50.714,0,0,0,9.359,0c2.066-.356,2.358-.962,2.335-1.344-.3-5.16-4.445-5.236-4.445-5.236h-4.828a5,5,0,0,0-4.58,5.236C-4021.047-707.939-4018.878-707.835-4018.874-707.816Z" transform="translate(4289.533 850.597)" fill="#fff" />
                                                <ellipse id="Ellipse_111" data-name="Ellipse 111" cx="4.181" cy="4.184" rx="4.181" ry="4.184" transform="translate(271.115 127)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                            </button>
                            <!-- only mobile view down arrow -->
                            <span class="ms-2 icon d-inline-block d-md-none">
                                <img src="assets/images/dropdwnArrow.svg" alt="">
                            </span>
                            <!-- only mobile view down arrow end -->
                            <div class="fontMed ms-2 d-none d-lg-block">
                                <p class="m-0 lh-1">Name surname
                                    <span class="ms-2 icon">
                                        <img src="assets/images/dropdwnArrow.svg" alt="">
                                    </span>
                                </p>
                                <p class="m-0 fs11">emailaddres&commat;gmail.com</p>
                            </div>
                        </div>

                        <ul class="dropdown-menu border-0 boxShadow rounded-3 p-3 dropdown-menu-center mt-2 dropdwonHeight"
                            aria-labelledby="userlist" data-simplebar>
                            <!-- Search item -->
                            <li class="mb-3">
                                <div class="position-relative searchbox">
                                    <input type="text" class="form-control rounded-5 border-0 inputH42 bg_light" placeholder="Search..." autocomplete="off">
                                    <span class="searchIcon position-absolute">
                                        <img src="assets/images/search_icon.svg" />
                                    </span>
                                </div>
                            </li>

                            <!-- list item -->
                            <li class="d-flex align-items-center mb-2 pb-1">
                                <button class="btn btn-primary commonBtn shadow-none rounded-pill primaryBg wh41 me-2 d-inline-flex align-items-center justify-content-center" type="button">
                                    <img class="wh41 rounded-pill" src="assets/images/user_big.png" alt="">
                                </button>
                                <p class="m-0 fs12 text-truncate userDropdownWidth">emailid&commat;gmail.com</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="headerRight d-flex align-items-center">
                    <!-- header button dropdown -->
                    <div class="dropdown">
                        <button class="border whiteBg btn-sm rounded-pill px-4 inputH42" type="button" id="addMemberBtn" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.88" height="2.16" viewBox="0 0 12.88 2.16">
                                <path id="Path_1254" data-name="Path 1254" d="M12.208,0V-2.16h2.224V0ZM6.88,0V-2.16H9.1V0ZM1.552,0V-2.16H3.776V0Z" transform="translate(-1.552 2.16)" fill="#4d4e58" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 px-2 dropdown-menu-end" aria-labelledby="addMemberBtn">
                            <!-- list item -->
                            <!-- <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg id="email_black_24dp_1_" data-name="email_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="14.21" height="14.21" viewBox="0 0 14.21 14.21">
                                            <path id="Path_40" data-name="Path 40" d="M0,0H14.21V14.21H0Z" fill="none" />
                                            <path id="Path_41" data-name="Path 41" d="M13.841,5.184A1.188,1.188,0,0,0,12.657,4H3.184A1.188,1.188,0,0,0,2,5.184v7.1a1.188,1.188,0,0,0,1.184,1.184h9.473a1.188,1.188,0,0,0,1.184-1.184Zm-1.184,0L7.921,8.145,3.184,5.184Zm0,7.1H3.184V6.368l4.737,2.96,4.737-2.96Z" transform="translate(-0.816 -1.632)" fill="#c9c9c9" />
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Edit Email</p>
                                </a>
                            </li> -->
                            <!-- list item -->
                            <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.679" height="14.679"
                                            viewBox="0 0 14.679 14.679">
                                            <g id="file_download_black_24dp_1_" data-name="file_download_black_24dp (1)" transform="translate(0.308 0.489)">
                                                <g id="Group_1052" data-name="Group 1052" transform="translate(-0.308 -0.489)">
                                                    <rect id="Rectangle_6086" data-name="Rectangle 6086" width="14.679" height="14.679" fill="none" />
                                                </g>
                                                <g id="Group_1053" data-name="Group 1053" transform="translate(2.984 3.07)">
                                                    <path id="Path_1179" data-name="Path 1179" d="M11.248,9.695v1.553H5.035V9.695H4v1.553a1.038,1.038,0,0,0,1.035,1.035h6.212a1.038,1.038,0,0,0,1.035-1.035V9.695ZM10.73,7.624,10,6.894,8.659,8.23V4H7.624V8.23L6.283,6.894l-.73.73,2.588,2.588Z" transform="translate(-4 -4)" fill="#c9c9c9" />
                                                </g>
                                            </g>
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Download QR code</p>
                                </a>
                            </li>
                            <!-- list item -->
                            <!-- <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg id="visibility_black_24dp_2_" data-name="visibility_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width="13.601" height="13.601" viewBox="0 0 13.601 13.601">
                                            <path id="Path_1175" data-name="Path 1175" d="M0,0H13.6V13.6H0Z" fill="none" />
                                            <path id="Path_1176" data-name="Path 1176" d="M7.234,5.133a5.537,5.537,0,0,1,5,3.117,5.566,5.566,0,0,1-10,0,5.537,5.537,0,0,1,5-3.117M7.234,4A6.7,6.7,0,0,0,1,8.25a6.7,6.7,0,0,0,12.467,0A6.7,6.7,0,0,0,7.234,4Zm0,2.834A1.417,1.417,0,1,1,5.817,8.25,1.417,1.417,0,0,1,7.234,6.834m0-1.133a2.55,2.55,0,1,0,2.55,2.55A2.554,2.554,0,0,0,7.234,5.7Z" transform="translate(-0.433 -1.733)" fill="#c9c9c9" />
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">View profile</p>
                                </a>
                            </li> -->
                            <!-- list item -->
                            <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg id="content_copy_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                            width="11.803" height="11.803" viewBox="0 0 11.803 11.803">
                                            <path id="Path_1177" data-name="Path 1177" d="M0,0H11.8V11.8H0Z" fill="none" />
                                            <path id="Path_1178" data-name="Path 1178" d="M9.163,1H3.023A1.014,1.014,0,0,0,2,2V8.978H3.023V2h6.14ZM10.7,2.995H5.07a1.014,1.014,0,0,0-1.023,1v6.981a1.014,1.014,0,0,0,1.023,1H10.7a1.014,1.014,0,0,0,1.023-1V3.992A1.014,1.014,0,0,0,10.7,2.995Zm0,7.978H5.07V3.992H10.7Z" transform="translate(-1.167 -0.584)" fill="#c9c9c9" />
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Duplicate member</p>
                                </a>
                            </li>
                            <!-- list item -->
                            <!-- <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            viewBox="0 0 14 14">
                                            <g id="admin_panel_settings_black_24dp" transform="translate(0 0.399)">
                                                <g id="Group_1048" data-name="Group 1048">
                                                    <rect id="Rectangle_6085" data-name="Rectangle 6085" width="14"
                                                        height="14" transform="translate(0 -0.399)" fill="none" />
                                                </g>
                                                <g id="Group_1050" data-name="Group 1050"
                                                    transform="translate(1.7 1.7)">
                                                    <g id="Group_1049" data-name="Group 1049">
                                                        <circle id="Ellipse_86" data-name="Ellipse 86" cx="0.635"
                                                            cy="0.635" r="0.635" transform="translate(7.299 6.449)"
                                                            fill="#c9c9c9" />
                                                        <path id="Path_1170" data-name="Path 1170"
                                                            d="M16.029,17.5c-.414,0-1.241.2-1.269.612a1.547,1.547,0,0,0,2.539,0C17.27,17.7,16.443,17.5,16.029,17.5Z"
                                                            transform="translate(-8.096 -9.283)" fill="#c9c9c9"
                                                            fill-rule="evenodd" />
                                                        <path id="Path_1171" data-name="Path 1171"
                                                            d="M11.5,7.585V4.853L7.25,3,3,4.853V7.636A5.788,5.788,0,0,0,7.25,13.2a5.194,5.194,0,0,0,.907-.312,3.4,3.4,0,1,0,3.344-5.3ZM7.534,10.934a3.268,3.268,0,0,0,.13.918,3.733,3.733,0,0,1-.414.17A4.651,4.651,0,0,1,4.133,7.636V5.6L7.25,4.235,10.367,5.6V7.585A3.4,3.4,0,0,0,7.534,10.934Zm3.4,2.267A2.267,2.267,0,1,1,13.2,10.934,2.266,2.266,0,0,1,10.934,13.2Z"
                                                            transform="translate(-3 -3)" fill="#c9c9c9"
                                                            fill-rule="evenodd" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Revoke admin status</p>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            viewBox="0 0 14 14">
                                            <g id="admin_panel_settings_black_24dp" transform="translate(0 0.399)">
                                                <g id="Group_1048" data-name="Group 1048">
                                                    <rect id="Rectangle_6085" data-name="Rectangle 6085" width="14"
                                                        height="14" transform="translate(0 -0.399)" fill="none" />
                                                </g>
                                                <g id="Group_1050" data-name="Group 1050"
                                                    transform="translate(1.7 1.7)">
                                                    <g id="Group_1049" data-name="Group 1049">
                                                        <circle id="Ellipse_86" data-name="Ellipse 86" cx="0.635"
                                                            cy="0.635" r="0.635" transform="translate(7.299 6.449)"
                                                            fill="#c9c9c9" />
                                                        <path id="Path_1170" data-name="Path 1170"
                                                            d="M16.029,17.5c-.414,0-1.241.2-1.269.612a1.547,1.547,0,0,0,2.539,0C17.27,17.7,16.443,17.5,16.029,17.5Z"
                                                            transform="translate(-8.096 -9.283)" fill="#c9c9c9"
                                                            fill-rule="evenodd" />
                                                        <path id="Path_1171" data-name="Path 1171"
                                                            d="M11.5,7.585V4.853L7.25,3,3,4.853V7.636A5.788,5.788,0,0,0,7.25,13.2a5.194,5.194,0,0,0,.907-.312,3.4,3.4,0,1,0,3.344-5.3ZM7.534,10.934a3.268,3.268,0,0,0,.13.918,3.733,3.733,0,0,1-.414.17A4.651,4.651,0,0,1,4.133,7.636V5.6L7.25,4.235,10.367,5.6V7.585A3.4,3.4,0,0,0,7.534,10.934Zm3.4,2.267A2.267,2.267,0,1,1,13.2,10.934,2.266,2.266,0,0,1,10.934,13.2Z"
                                                            transform="translate(-3 -3)" fill="#c9c9c9"
                                                            fill-rule="evenodd" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Make admin</p>
                                </a>
                            </li> -->
                            <!-- list item -->
                            <!-- <li>
                                <a class="dropdown-item d-flex align-items-center">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                            <g id="lock_reset_black_24dp" transform="translate(13.7 14) rotate(180)">
                                                <rect id="Rectangle_6098" data-name="Rectangle 6098" width="14" height="14" transform="translate(-0.3)" fill="none" />
                                                <path id="Path_1190" data-name="Path 1190" d="M7.765,3A5.151,5.151,0,0,0,2.614,8.151c0,.034.006.069.006.109L1.567,7.207.76,8.014,3.187,10.44,5.613,8.014l-.807-.807L3.765,8.248c0-.034-.006-.063-.006-.1a4.015,4.015,0,1,1,1.213,2.867l-.813.807A5.15,5.15,0,1,0,7.765,3Z" transform="translate(-0.361 -1.283)" fill="#c9c9c9" />
                                            </g>
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Reset password</p>
                                </a>
                            </li> -->
                            <!-- list item -->
                            <!-- <li>
                                <a class="dropdown-item d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#   ">
                                    <i class="icon me-3 d-flex align-items-center justify-content-center">
                                        <svg id="delete_outline-24px" xmlns="http://www.w3.org/2000/svg" width="15.584" height="15.584" viewBox="0 0 15.584 15.584">
                                            <path id="Path_808" data-name="Path 808" d="M0,0H15.584V15.584H0Z" fill="none" />
                                            <path id="Path_809" data-name="Path 809" d="M5.649,13.39a1.3,1.3,0,0,0,1.3,1.3h5.195a1.3,1.3,0,0,0,1.3-1.3V5.6H5.649ZM6.948,6.9h5.195V13.39H6.948Zm4.87-3.247L11.169,3H7.922l-.649.649H5v1.3h9.091v-1.3Z" transform="translate(-1.753 -1.052)" fill="#c9c9c9" />
                                        </svg>
                                    </i>
                                    <p class="m-0 fs12 fw-bold">Remove from team</p>
                                </a>
                            </li> -->
                        </ul>
                      
                    </div>
                </div>
            </div>
            <!-- Header layout end -->

            <!-- Only mobile Header layout start -->
            <div class="layoutHeader d-flex align-items-center justify-content-center pb-4 d-block d-sm-none" >
                <div class="headerRight d-flex align-items-center">
                    <!-- header list view toggle button -->
                    <div class="changeViewToggle buttonPill">
                        <!-- // added on 17 nov 2022 start -->
                        <mat-button-toggle-group class="rounded-pill border-0 whiteBg fs12" #group="matButtonToggleGroup">
                            <mat-button-toggle value="personal" aria-label="personal"> Personal </mat-button-toggle>
                            <mat-button-toggle value="business" aria-label="business"> Business </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <!-- Only Mobile Header layout end -->

            <div class="card border-0 rounded27 shadow-sm hcustom-100 overflow-hidden">
                <div class="cardInnerContainer profileLayout d-flex flex-lg-row h-100 flex-column" [ngClass]="drawerToggle==true?'midSidebarOpen':''">
                    <!-- sidebar  -->
                    <div class="innderSidebarContainer border-end d-lg-block flex-shrink-0">
                        <!-- mobile close button -->
                        <div class="cursor_pointer d-flex d-lg-none p-4 pb-0" (click)="drawerToggle=false">
                            <svg id="clear-24px-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path id="Path_66" data-name="Path 66" d="M0,0H24V24H0Z" fill="none" />
                                <path id="Path_67" data-name="Path 67"
                                    d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                    fill="#c1c1c1" />
                            </svg>
                            <span class="ps-2">Close</span>
                        </div>

                        <div class="sidebar_inner p-4 pb-0" data-simplebar>
                            <ul class="sidebar list-unstyled fs13 fontMed m-0">
                                <li class="listItem d-flex align-items-center" [routerLink]="['create']" [routerLinkActive]="['active']">
                                    <i class="icon">
                                        <img src="assets/images/menu-person_black_24dp.svg"/>
                                    </i>
                                    <span>Create Profiles</span>
                                </li>
                                <li class="listItem d-flex align-items-center" (click)="changeDrawerText('Content')" [routerLink]="['content']" [skipLocationChange]="true" [routerLinkActive]="['active']">
                                    <i class="icon">
                                        <img src="assets/images/profile_apps_black_24dp.svg"/>
                                    </i>
                                    <span>Content</span>
                                </li>
                                <!-- item -->
                                <li class="listItem d-flex align-items-center" [routerLink]="['profile-template']" [routerLinkActive]="['active']">
                                    <i class="icon">
                                        <img src="assets/images/profile_account_box_black_24dp.svg"/>
                                    </i>
                                    <span>Profile Template</span>
                                </li>
                                <!-- item -->
                                <li class="listItem d-flex align-items-center" [routerLink]="['QR-code']" [skipLocationChange]="true" [routerLinkActive]="['active']">
                                    <i class="icon">
                                        <img src="assets/images/profile_qr_code_black_24dp.svg"/>
                                    </i>
                                    <span>QR Code</span>
                                </li>
                            </ul>
                        </div>

                        <ul class="list-unstyled sidebar list-unstyled fs13 fontMed mb-auto ps-4">
                            <li class="listItem mb-0">
                                <p class="mb-0">Last login date: <br> 10-30-2024</p>
                                <!-- <p class="mb-0"></p> -->
                            </li>
                        </ul>
                    </div>

                    <main class="mainRightContainer p-4 border-end flex-fill position-relative">
                        <!-- Mobile toggle menu button -->
                        <div class="d-flex d-lg-none position-relative align-items-center mb-3">
                            <button class="border-0 bg-transparent" (click)="drawerToggle=true">
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" height="20" viewBox="0 0 32 32" width="20">
                                    <g id="Layer_1" fill="rgb(0,0,0)">
                                        <path d="m29 8h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                        <path d="m29 28h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                        <path d="m29 18h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                    </g>
                                </svg>
                            </button>
                            <span class="ps-2 fontMed pt-1">Profile_Template</span>
                        </div>

                        <div class="midSection h-100">
                            <router-outlet></router-outlet>
                        </div>
                    </main>

                    <!-- Mobile Live preview template section -->
                    <div class="livepreview d-flex align-items-center flex-column justify-content-center h-100 p-4" *ngIf="showPhone()" >
                        <h6 class="fs12 mb-4">Personal profile live preview</h6>
                        <div class="phone_wrap" data-simplebar>
                            <div class="phonePreview position-relative"
                                style="background:url(assets/images/phone_img.png) no-repeat center;">
                                <div class="preview_container">
                                    <app-social-template></app-social-template>
                                    <app-business-template></app-business-template>
                                    <!-- share contact modal -->
                                    <div class="share_contact_main position-absolute">
                                        <div class="inner-sec position-relative w-100 d-flex align-items-center justify-content-center">
                                            <div class="modal_content position-absolute">
                                                <div class="ModelAvatar">
                                                    <img src="assets/images/userdummywithbg.png" class="fit_img" alt="">
                                                </div>
                                                <div class="head-content">
                                                    <button class="btn p-0 m-0 border-0 lh-1 ms-auto d-flex mt-1 me-1 CloseBtn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="5.736" height="5.736" viewBox="0 0 5.736 5.736">
                                                            <path id="Path_67" data-name="Path 67" d="M10.736,5.578,10.158,5,7.868,7.29,5.578,5,5,5.578l2.29,2.29L5,10.158l.578.578,2.29-2.29,2.29,2.29.578-.578-2.29-2.29Z" transform="translate(-5 -5)" fill="#9f9f9f" />
                                                        </svg>
                                                    </button>
                                                    <h3 class="fs11 fontMed text text_primary text-center mb-2"> popupFormHeader</h3>
                                                </div>
                                                <form class="form_sec mt-1 pt-2">
                                                    <ng-container formArrayName="data">
                                                        <div class="mb-2">
                                                            <ng-container>
                                                                <input readonly type="text" class="form-control" formControlName="field_name">
                                                                <div>
                                                                    <select class="form-control"
                                                                        aria-label="Default select example" readonly>
                                                                        <option selected> option 1</option>
                                                                        <ng-container formArrayName="field_option">
                                                                            <ng-container>
                                                                                <option>
                                                                                    option 02
                                                                                </option>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </select>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <button type="submit" class="btn commonBtn rounded-pill w-100 text-white shadow-none fw-bold mb-2 connectBtn p-0">Connect</button>
                                                    <p class="mb-1 text-center">popupFormFooter</p>
                                                </form>
                                            </div>
                                            <div class="overlay"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <h6 class="fs12 mt-3 mb-0">Lead capture mode on</h6> -->
                        <button class="btn btn-default border rounded-pill fs11 px-4 py-1 mt-3" >View profile</button>
                    </div>
                   
                    <!-- QR code -->
                    <div *ngIf="currentRoute == '/profile/QR-code'" class="livepreview qrCode d-flex flex-column h-100 p-4">
                        <div class="inner-sec">
                            <h5 class="text-center fs12 mb-5 mt-3">John's QR code</h5>
                            <div #qr class="qr-box position-relative mx-auto mb-5">
                                  <img class="img-fluid" src="assets/images/qr-code.svg">
                                <div class="logo-wrap wh60 rounded-2 overflow-hidden position-absolute d-flex align-items-center justify-content-center p-1">
                                    <img src="https://s.tmimgcdn.com/scr/1200x750/294300/blue-water-wave-logo-vector-icon-illustration11_294365-original.jpg" class="fit_img">
                                </div>
                            </div>
                            <ng-container *ngIf="currentRoute == '/profile/QR-code'" class="router_overlay z-9">
                                <button class="btn btn-primary mx-auto commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed d-block h36" (click)="qrLogo.click()"> Upload a Logo</button>
                                 <input #qrLogo type="file" class="d-none">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="download" style="display:none">
    <img #canvas />
    <a #downloadLink></a>
</div>
<!-- </div> -->
<div class="modal fade" id="removeCard" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="removeSubteamLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2 border-0 rounded27 shadow-lg">
            <div class="p-4 pb-2 modal-body">
                <p class="fs14 m-0">Are you sure you want to delete this card? All info on this card will be deleted and you will not be able to recover it.</p>
            </div>

            <div class="btns-sec modal-footer p-4 text-end border-0">
                <button class="btn whiteBg border rounded-pill py-0 fs12 fontMed px-3 h36 me-2" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                <button class="btn btn-primary commonBtn rounded-pill py-0 fs12 fontMed px-3 h36 ms-1" data-bs-dismiss="modal" aria-label="Close">Delete card</button>
            </div>
        </div>
    </div>
</div>
