import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MembersComponent } from './components/members/members.component';
import { MembersModule } from './components/members/members.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddLinksContactComponent } from './components/modals/add-links-contact/add-links-contact.component';
import { AddLinksComponent } from './components/modals/add-links/add-links.component';
import { ProfileModule } from './components/profile/profile.module';
import { CustomizeProfileTemplateComponent } from './components/members/customize-profile-template/customize-profile-template.component';
import { EditLinkComponent } from './components/modals/edit-link/edit-link.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { CoreModule } from './common/interceptor/core.module';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DuplicateMemberComponent } from './components/modals/duplicate-member/duplicate-member.component';
import { ChangePasswordModule } from './components/change-password/change-password.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    MembersComponent,
    AddLinksContactComponent,
    AddLinksComponent,
    CustomizeProfileTemplateComponent,
    EditLinkComponent,
    // ChangePasswordComponent,
    DuplicateMemberComponent,
    EditLinkComponent
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ChangePasswordModule,
    AppRoutingModule,
    MembersModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    MatChipsModule,
    NgxSpinnerModule,
    ProfileModule,
    MatSlideToggleModule,
    CommonModule,
    CoreModule
   
    

  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
