import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialTemplateComponent } from './social-template.component';



@NgModule({
  declarations: [SocialTemplateComponent],
  imports: [
    CommonModule,
  ],
  exports: [SocialTemplateComponent]
})
export class SocialTemplateModule { }
