<header class="whiteBg position-sticky w-100 px-3 d-block d-xl-none">
    <div class="headerContainer d-flex align-items-center justify-content-between">
        <div class="navbar-brand text-center">
            <img src="assets/images/logo_login.png" alt="">
        </div>

        <div class="toggleIcon">
            <button type="button"
                class="btn btn-sm px-3 d-flex align-items-center header-item vertical-menu-btn topnav-hamburger"
                id="menuIcon" (click)="checkVisited()">
                <span class="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </button>
        </div>
    </div>
</header>
<div class="layout-wrapper position-relative">
    <ng-container *ngIf="geturl=='showdrawer'">
        <app-sidebar></app-sidebar>
    </ng-container>
    <router-outlet></router-outlet>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>