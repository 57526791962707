import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, delay, retryWhen, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagehandlingService } from '../message/messagehandling.service';

export const maxRetries = 2;
export const delayMs = 2000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router, private message: MessagehandlingService
  ) { }
 
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        retryWhen(errors =>
          errors.pipe(
            mergeMap((error, index) => {
              if (index < maxRetries && (error.status === 0 || error.status === 500)) {
                // Retry for network errors or server errors
                return of(error).pipe(delay(delayMs));
              }
              return throwError(error);
            })
          )
        ),
        tap(
          (event: HttpEvent<any>) => {
            // Success handling if needed
          }
        ),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            // Network error handling
            this.message.errorMessage('Network error: Please check your internet connection.', 2000);
          } else {
            switch (error.status) {
              case 400:
                // Handle 400 error
                this.message.sucessMessage(error.error.msg, 1000);
                break;
              case 401:
                // Auto logout if 401 response returned from API
                sessionStorage.clear();
                localStorage.clear();
                this.message.errorMessage('You have been logged out for security purposes. Please login again.', 2000);
                this.router.navigate(['/']);
                break;
              default:
                // Handle other server-side errors
                this.message.errorMessage(error.error.msg, 1000);
                break;
            }
          }
          return throwError(error);
        })
      );
    }
  }
  