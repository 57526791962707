<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button (click)="backToAddContent()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add Calendar link to 1 <span>profile</span></h5>
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap">
                <img src="../../../../assets/images/icons/call.png" class="imgCls">
            </div>
            <!-- // added on 16 nov 2022 start -->
            <p class="fs11 text_gray mt-2 mb-0 lh-1">Calendar</p>
        </div>
        <div class="padding-content pt-0">
            <!-- form content -->
            <!-- // added on 25 nov 2022 start -->
            <form>
                <div class="inputwrap position-relative mb-3 mt-4">
                    <input type="text" formControlName="content" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed pe-5" placeholder="URL">
                    <img src="assets/images/questionicn.svg" data-bs-toggle="modal" class="icnbtn cursor_pointer"  data-bs-target="#userbameInfo">
                </div>
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3 mb-3">
                    <label class="fs14 text_gray fontMed mb-0">Titles</label>
                    <input formControlName="title" class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none">
                </div>
                <button class="btn commonBtn rounded-pill fs17 w-100 text-white shadow-none">Add link</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">How to get your info username</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">tips</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto" data-bs-dismiss="modal" style="color:#498BE5;">Got it!</button>
            </div>
        </div>
    </div>
</div>