import { Component } from '@angular/core';
import { AddLinksComponent } from '../../modals/add-links/add-links.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddLinksContactComponent } from '../../modals/add-links-contact/add-links-contact.component';
import { EditLinkComponent } from '../../modals/edit-link/edit-link.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  constructor( public dialog: MatDialog, private router: Router) {
   
  }

  addContent() {
    sessionStorage.setItem('SelectedMembersCount', '1')
    this.dialog.open(AddLinksContactComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    })
  }

  editLink() {
    this.dialog.open(EditLinkComponent, {
      panelClass: ['cmn_modal', 'modal_md'],
      width: '390px',
    });
  }

  // editLink(data: any): void {
  //   default:
  //       this.dialog.open(EditLinkComponent, {
  //         panelClass: ['cmn_modal', 'modal_sm'],
  //         width: '390px',
  //         data: { block: data },
  //       })
  //         .afterClosed()
  //         .subscribe((res) => {
  //           setTimeout(() => {
  //             this.getEditIcons()
  //           }, 1000);
  //         });
  //   }
  
}
