<div class="layout-wrapper position-relative">
    <!-- <button [routerLink]="['/inviteAccepted']" style="margin-left:50rem">invite</button> -->
    <div class="mainContent">
        <div class="mainContentInner pb-5 mb-3">
            <div class="container-fluid">
                <!-- Header layout start -->
                <div class="layoutHeader d-flex align-items-center justify-content-between pt-4">
                    <div class="headerLeft">
                        <p class="fontMed m-0">Members<span class="text_light"> ({{membersData?.length}})</span></p>
                    </div>
                    <div class="headerRight d-flex align-items-center">
                        <!-- header list view toggle button -->
                        <div class="changeViewToggle me-3 d-none d-sm-block">
                            <mat-button-toggle-group class="rounded-pill" #group="matButtonToggleGroup" value="GridView"
                                (change)="changeToggleView($event.value)">
                                <mat-button-toggle value="GridView" aria-label="Grid View">
                                    <i class="icon">
                                        <img src="assets/images/dashboard_icon.svg" alt="">
                                    </i>
                                </mat-button-toggle>
                                <mat-button-toggle value="ListView" aria-label="List View">
                                    <i class="icon">
                                        <img src="assets/images/list.svg" alt="">
                                    </i>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <!-- <div class="example-selected-value">Selected value: {{group.value}}</div> -->
                        </div>
                        <!-- header Search box -->
                        <div class="position-relative searchbox me-3 d-none d-sm-block">
                            <input type="text" class="form-control rounded-5 border-0 inputH42 " placeholder="Search..."
                                autocomplete="off">
                            <span class="searchIcon position-absolute"><img
                                    src="assets/images/search_icon.svg" /></span>
                        </div>
                        <!-- header button dropdown -->
                        <div class="dropdown">
                            <button
                                class="btn btn-primary btn_xl rounded-pill commonBtn dropdown-toggle px-4 buttonConvert"
                                type="button" id="addMemberBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="d-none d-md-inline-block">Add Members</span>
                                <i class="fst-normal fontLight m-0 h4 lh-1 d-inline-block d-md-none">+</i>
                            </button>
                            <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 dropdown-menu-end dropdownIconWithBox"
                                aria-labelledby="addMemberBtn">
                                <!-- list item -->
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                        [routerLink]="['/add-members/addbyemail']" [skipLocationChange]="true"
                                        [routerLinkActive]="['active']">
                                        <i class="icon me-3 d-flex align-items-center justify-content-center">
                                            <svg id="email_grey_24dp" xmlns="http://www.w3.org/2000/svg" width="19.464"
                                                height="19.464" viewBox="0 0 19.464 19.464">
                                                <path id="Path_40" data-name="Path 40" d="M0,0H19.464V19.464H0Z"
                                                    fill="none" />
                                                <path id="Path_41" data-name="Path 41"
                                                    d="M18.22,5.622A1.627,1.627,0,0,0,16.6,4H3.622A1.627,1.627,0,0,0,2,5.622v9.732a1.627,1.627,0,0,0,1.622,1.622H16.6a1.627,1.627,0,0,0,1.622-1.622Zm-1.622,0L10.11,9.677,3.622,5.622Zm0,9.732H3.622V7.244L10.11,11.3,16.6,7.244Z"
                                                    transform="translate(-0.378 -0.756)" fill="#afafaf" />
                                            </svg>
                                        </i>
                                        <div class="dropdownTextContainer">
                                            <p class="m-0 fs13 fw-bold">Add by Email</p>
                                            <p class="m-0 fs11">Add teammates via email address</p>
                                        </div>
                                    </a>
                                </li>

                                <!-- list item -->
                                <li>
                                    <a [routerLink]="['/add-members/importviacsv']" [skipLocationChange]="true"
                                        [routerLinkActive]="['active']" class="dropdown-item d-flex align-items-center">
                                        <i class="icon me-3 d-flex align-items-center justify-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.619" height="17.433"
                                                viewBox="0 0 13.619 17.433">
                                                <g id="layer1" transform="translate(-1.661 0.25)">
                                                    <g id="g1702" transform="translate(-2.746 3.817)">
                                                        <g id="g1697" transform="translate(-0.166 0.118)">
                                                            <path id="path1710"
                                                                d="M5.087-3.936a.265.265,0,0,0-.264.264v16.4A.265.265,0,0,0,5.087,13H17.669a.265.265,0,0,0,.264-.264V1.435a.265.265,0,0,0-.075-.186L12.9-3.833v0l-.02-.02-.022-.02-.024-.018-.024-.014L12.8-3.913l-.026-.01-.014,0h-.006l-.038-.006-.014,0H5.087Zm.264.529h7.226L17.4,1.542V12.469H5.352Z"
                                                                fill="#afafaf" stroke="#aeafae" stroke-width="0.5"
                                                                opacity="0.99" />
                                                            <path id="path1706"
                                                                d="M12.683-3.936a.265.265,0,0,0-.256.264V1.435a.265.265,0,0,0,.264.264h4.979a.265.265,0,0,0,.19-.45l-4.979-5.1a.265.265,0,0,0-.2-.081Zm.273.916,4.088,4.19H12.955Z"
                                                                fill="#afafaf" stroke="#aeafae" stroke-width="0.5"
                                                                opacity="0.99" />
                                                        </g>
                                                    </g>
                                                    <g id="text26" opacity="0.99">
                                                        <path id="path29"
                                                            d="M6.953,14.692q0,.041,0,.072a.233.233,0,0,1-.01.054.147.147,0,0,1-.016.041.226.226,0,0,1-.036.044.792.792,0,0,1-.109.08,1.437,1.437,0,0,1-.207.1,1.668,1.668,0,0,1-.282.083,1.661,1.661,0,0,1-.935-.072,1.2,1.2,0,0,1-.447-.32,1.49,1.49,0,0,1-.284-.522,2.662,2.662,0,0,1,.008-1.46,1.613,1.613,0,0,1,.3-.55A1.283,1.283,0,0,1,5.4,11.9,1.478,1.478,0,0,1,6,11.78a1.382,1.382,0,0,1,.282.028,1.693,1.693,0,0,1,.253.067,1.15,1.15,0,0,1,.207.1.605.605,0,0,1,.124.09.263.263,0,0,1,.047.052.146.146,0,0,1,.016.044.24.24,0,0,1,.01.057.535.535,0,0,1,.005.08.634.634,0,0,1-.005.088.271.271,0,0,1-.016.062.1.1,0,0,1-.026.036.052.052,0,0,1-.034.01.194.194,0,0,1-.1-.049,1.349,1.349,0,0,0-.168-.111,1.2,1.2,0,0,0-.248-.111,1.041,1.041,0,0,0-.349-.052.9.9,0,0,0-.406.09.846.846,0,0,0-.31.261,1.308,1.308,0,0,0-.2.424,2.1,2.1,0,0,0-.07.568,2.14,2.14,0,0,0,.067.563,1.217,1.217,0,0,0,.2.408.849.849,0,0,0,.315.248,1.027,1.027,0,0,0,.421.083,1.1,1.1,0,0,0,.346-.049,1.361,1.361,0,0,0,.253-.109q.106-.062.173-.111a.205.205,0,0,1,.106-.049.059.059,0,0,1,.031.008.055.055,0,0,1,.021.031.219.219,0,0,1,.01.059.766.766,0,0,1,.005.1Z"
                                                            fill="#afafaf" stroke="#aeafae" stroke-width="0.5" />
                                                        <path id="path31"
                                                            d="M9.338,14.227a.958.958,0,0,1-.088.419.891.891,0,0,1-.24.313,1.038,1.038,0,0,1-.362.191,1.491,1.491,0,0,1-.447.065,1.623,1.623,0,0,1-.313-.028,1.761,1.761,0,0,1-.256-.07,1.318,1.318,0,0,1-.189-.085.554.554,0,0,1-.106-.075.212.212,0,0,1-.044-.078.524.524,0,0,1-.013-.129.681.681,0,0,1,.005-.093.231.231,0,0,1,.016-.062.06.06,0,0,1,.026-.031.064.064,0,0,1,.036-.01.2.2,0,0,1,.1.044,1.737,1.737,0,0,0,.171.1,1.771,1.771,0,0,0,.248.1,1.186,1.186,0,0,0,.338.044.847.847,0,0,0,.264-.039.62.62,0,0,0,.207-.109.48.48,0,0,0,.134-.176.567.567,0,0,0,.047-.235.441.441,0,0,0-.065-.243.635.635,0,0,0-.171-.176,1.361,1.361,0,0,0-.243-.14q-.134-.065-.276-.129t-.276-.147a1.174,1.174,0,0,1-.24-.189A.9.9,0,0,1,7.429,13a.871.871,0,0,1-.065-.351.881.881,0,0,1,.075-.372A.786.786,0,0,1,7.651,12a.944.944,0,0,1,.323-.168,1.343,1.343,0,0,1,.406-.059,1.213,1.213,0,0,1,.222.021,1.271,1.271,0,0,1,.212.052,1.011,1.011,0,0,1,.178.072.373.373,0,0,1,.1.065.15.15,0,0,1,.034.039.124.124,0,0,1,.013.036.331.331,0,0,1,.008.052q0,.031,0,.08a.59.59,0,0,1-.005.083.29.29,0,0,1-.01.062.078.078,0,0,1-.023.036.048.048,0,0,1-.031.01.216.216,0,0,1-.09-.036q-.059-.036-.147-.08a1.152,1.152,0,0,0-.209-.083.865.865,0,0,0-.269-.039.691.691,0,0,0-.243.039.483.483,0,0,0-.171.1.4.4,0,0,0-.1.147.489.489,0,0,0-.034.181.436.436,0,0,0,.065.24.668.668,0,0,0,.171.178,1.583,1.583,0,0,0,.245.142l.279.132a3.064,3.064,0,0,1,.279.145,1.082,1.082,0,0,1,.243.186.8.8,0,0,1,.173.253.81.81,0,0,1,.067.344Z"
                                                            fill="#afafaf" stroke="#aeafae" stroke-width="0.5" />
                                                        <path id="path33"
                                                            d="M11.292,15.087a.125.125,0,0,1-.028.047.123.123,0,0,1-.052.028.32.32,0,0,1-.083.013A1.215,1.215,0,0,1,11,15.18l-.1,0a.443.443,0,0,1-.072-.005l-.054-.01a.18.18,0,0,1-.036-.018.113.113,0,0,1-.026-.023.363.363,0,0,1-.016-.036L9.607,11.979a.365.365,0,0,1-.023-.09.064.064,0,0,1,.023-.054.148.148,0,0,1,.075-.023q.052-.005.14-.005a.963.963,0,0,1,.114.005.2.2,0,0,1,.067.013.069.069,0,0,1,.034.028.153.153,0,0,1,.023.041l.959,2.814h0l.933-2.809a.2.2,0,0,1,.018-.047.069.069,0,0,1,.036-.028.237.237,0,0,1,.072-.013,1.169,1.169,0,0,1,.124-.005.8.8,0,0,1,.127.008.1.1,0,0,1,.062.023.061.061,0,0,1,.013.054.366.366,0,0,1-.023.09Z"
                                                            fill="#afafaf" stroke="#aeafae" stroke-width="0.5" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </i>
                                        <div class="dropdownTextContainer">
                                            <p class="m-0 fs13 fw-bold">Import via CSV</p>
                                            <p class="m-0 fs11">Add teammates via CSV file</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Header layout end -->
                <!-- Mobile custom header start -->
                <div class="mobile_toggleView_header d-flex align-items-center justify-content-between my-3 d-sm-none">
                    <!-- header list view toggle button -->
                    <div class="changeViewToggle me-3">
                        <mat-button-toggle-group class="rounded-pill" #group="matButtonToggleGroup" value="GridView"
                            (change)="changeToggleView($event.value)">
                            <mat-button-toggle value="GridView" aria-label="Grid View">
                                <i class="icon"><img src="assets/images/dashboard_icon.svg" alt=""></i>
                            </mat-button-toggle>
                            <mat-button-toggle value="ListView" aria-label="List View">
                                <i class="icon"><img src="assets/images/list.svg" alt=""></i>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <!-- <div class="example-selected-value">Selected value: {{group.value}}</div> -->
                    </div>
                    <!-- header Search box -->
                    <div class="position-relative searchbox flex-fill">
                        <input type="text" class="form-control rounded-5 border-0 inputH42 " placeholder="Search..."
                            autocomplete="off">
                        <span class="searchIcon position-absolute"><img src="assets/images/search_icon.svg" /></span>
                    </div>
                </div>
                <!-- Mobile custom header end -->
                <main>
                    <!-- Grid View Code start-->
                    <div class="members_grid_container mt-3" *ngIf="isGridView">
                        <mat-checkbox class="custom_check fs12 fontMed letterspacingfull checkBg mb-3 ms-2"
                            [(ngModel)]="selectAllCheckbox" [checked]="selectAllCheckbox"
                            (change)="selectAllMember($event)">
                            Select All
                        </mat-checkbox>
                        <div class="row">
                            <!-- member card  -->
                            <div class="col-12 col-xl-4 col-sm-6 mb-4" *ngFor="let member of membersData">
                                <div class="card p-2 border-0 rounded27 memberProfileCard position-relative">
                                    <div class="cardHeader rounded20" style="background: rgb(108 97 225 / 20%)">

                                        <div *ngIf="member.member_status!='4'"
                                            class="cardHeaderOptions d-flex align-items-center justify-content-between">
                                            <mat-checkbox [ngClass]="{'invisible' :member.member_status=='4'}"
                                                [checked]="member.checked" [(ngModel)]="member.checked"
                                                (change)="singleSelectMember($event,member)"
                                                class="custom_check"></mat-checkbox>

                                            <div class="dropdown">
                                                <button
                                                    class="border-0 whiteBg btn-sm rounded-pill px-3 py-2 lh-1 d-flex align-items-center justify-content-center"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <img src="../../../assets/images/3_dots.svg" alt="">
                                                </button>
                                                <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 dropdown-menu-end"
                                                    aria-labelledby="dropdownMenuButton1">
                                                    <!-- <li>
                                                        <a class="dropdown-item fw-bold fs12">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/lock_black_24dp.svg" alt="">
                                                            </i>
                                                            <span>Lock member</span>
                                                        </a>
                                                    </li> -->
                                                    <li *ngIf="member.lock_status=='0'"
                                                        (click)="lockMemberStatus(1,member.user_id)"><a
                                                            class="dropdown-item fw-bold fs12"><i class="icon me-2"><img
                                                                    src="assets/images/lock_black_24dp.svg" alt=""></i>
                                                            <span>Lock
                                                                member</span></a>
                                                    </li>
                                                    <li *ngIf="member.lock_status=='1'"
                                                        (click)="lockMemberStatus(0,member.user_id)"><a
                                                            class="dropdown-item fw-bold fs12"><i class="icon me-2"><img
                                                                    src="assets/images/lock_open_black_24dp.svg"
                                                                    alt=""></i>
                                                            <span>Unlock
                                                                member</span></a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item fw-bold fs12">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/file_download_black_24dp.svg"
                                                                    alt="">
                                                            </i>
                                                            <span>Download QR code</span>
                                                        </a>
                                                    </li>
                                                    <div style="display: none;">
                                                        <a href="assets/images/file_download_black_24dp.svg">
                                                            <img src="assets/images/file_download_black_24dp.svg"
                                                                alt="W3Schools" width="104" height="142">
                                                        </a>
                                                    </div>
                                                    <li>
                                                        <a class="dropdown-item fw-bold fs12">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/visibility_black_24dp.svg"
                                                                    alt="">
                                                            </i>
                                                            <span>View profile</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item fw-bold fs12" (click)="duplicatemember(member.user_id)">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/content_copy_black_24dp.svg"
                                                                    alt="">
                                                            </i>
                                                            <span>Duplicate member</span>
                                                        </a>
                                                    </li>

                                                    <li (click)="roleCheck('user',member.user_id)" *ngIf="member.role=='admin'">
                                                        <a class="dropdown-item d-flex align-items-center">
                                                            <i class="icon me-2">
                                                                <img src="../../../assets/images/revoke-admin.svg"
                                                                    alt="">
                                                            </i>
                                                            <p class="m-0 fs12 fw-bold">Revoke admin status</p>
                                                        </a>
                                                    </li>

                                                    <li *ngIf="member.role=='user'" (click)="roleCheck('admin',member.user_id)"><a
                                                        class="dropdown-item fw-bold fs12"><i class="icon me-2"><img
                                                            src="assets/images/admin_panel_settings_black_24dp.svg" alt=""></i> <span>Make
                                                          admin</span></a>
                                                    </li>
                                                </ul>
                                                <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 dropdown-menu-end"
                                                    aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a class="dropdown-item fw-bold fs12">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/file_download_black_24dp.svg"
                                                                    alt="">
                                                            </i>
                                                            <span>Download QR code</span>
                                                        </a>
                                                    </li>
                                                    <div style="display: none;">
                                                        <a href="assets/images/file_download_black_24dp.svg">
                                                            <img src="assets/images/file_download_black_24dp.svg"
                                                                alt="W3Schools" width="104" height="142">
                                                        </a>
                                                    </div>
                                                    <li>
                                                        <a class="dropdown-item fw-bold fs12">
                                                            <i class="icon me-2">
                                                                <img src="assets/images/visibility_black_24dp.svg"
                                                                    alt="">
                                                            </i>
                                                            <span>View profile</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body p-0 text-center">
                                        <div class="memberProfileImgContainer mx-auto position-relative">
                                            <div
                                                class="memberProfileImg boxShadow rounded-circle primaryBg d-flex align-items-center justify-content-center overflow-hidden ">
                                                <img *ngIf="!member?.photo" class="img-fluid"
                                                    src="assets/images/memberProfile_icon.svg" alt="">
                                                <img *ngIf="member?.photo" class="fit_img"
                                                    [src]="imageurl +member?.photo" alt="">


                                            </div>
                                            <div class="logo-wrap">
                                                <img *ngIf="!member?.logo"
                                                    src="https://s.tmimgcdn.com/scr/1200x750/294300/blue-water-wave-logo-vector-icon-illustration11_294365-original.jpg"
                                                    alt="">
                                                <img *ngIf="member?.logo" [src]="imageurl + member?.logo" alt="">

                                            </div>
                                        </div>

                                        <div class="memberInfo">
                                            <div class="text-uppercase fontMed fs9 text_light px-3 py-2">
                                                <span *ngIf="member?.name"
                                                    class="text_secondary fs12 fontMed me-2">{{member?.name}}</span>
                                                <span *ngIf="!member?.name"
                                                    class="text_secondary fs12 fontMed me-2">{{member?.email}}</span>

                                                <span
                                                    class="badge rounded-pill bg_light text-uppercase fontMed fs9 text_light py-2 px-3">{{member?.role}}</span>
                                                <img *ngIf="member?.lock_status==1"
                                                    src="assets/images/lock_black_24dp.svg" alt="">
                                            </div>
                                            <p mat-raised-button matTooltipClass="custom_tooltip"
                                                matTooltipPosition="above"
                                                matTooltip="A member is pending if the user has not accepted their email invite to join your team yet. If necessary. resend their invite below."
                                                class="m-0 fs12 fontMed text_secondary mt-2 bg-transparent position-relative">
                                                Pending Invitation
                                                <span>
                                                    <img src="../../../assets/images/info_black_24dp.svg" alt="">
                                                </span>
                                            </p>
                                        </div>
                                        <button
                                            class="border whiteBg btn-sm rounded-pill px-3 py-2 lh-1 fs11 mt-3 text_secondary fontMed assignMemberBtn"
                                            type="button">Invite to POD app</button>
                                    </div>

                                    <div class="card-footer border-0 bg-transparent py-4">
                                        <div class="profileCardFooter">
                                            <ul
                                                class="list-unstyled m-0 fs11 d-flex align-items-center justify-content-around">
                                                <li mat-raised-button matTooltipClass="custom_tooltip"
                                                    matTooltipPosition="above" matTooltip="Number of views">
                                                    <i class="icon me-2">
                                                        <img src="../../../assets/images/visibility_24dp.svg" alt="">
                                                    </i>
                                                    <span>4</span>
                                                </li>
                                                <li mat-raised-button matTooltipClass="custom_tooltip"
                                                    matTooltipPosition="above" matTooltip="Number of leads">
                                                    <i class="icon me-2">
                                                        <img src="../../../assets/images/contacts_24dp.svg" alt="">
                                                    </i>
                                                    <span>5</span>
                                                </li>
                                                <li mat-raised-button matTooltipClass="custom_tooltip"
                                                    matTooltipPosition="above" matTooltip="Number of devices">
                                                    <i class="icon me-2">
                                                        <img src="../../../assets/images/device_24dp.svg" alt="">
                                                    </i>
                                                    <span>15</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="router_overlay" [routerLink]="['/profile']"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Grid View Code End-->
                    <!-- List View Code start-->
                    <div class="listviewContainer mt-3" *ngIf="isListView">
                        <mat-checkbox class="custom_check fs12 fontMed letterspacingfull checkBg ms-2 mb-14"
                            [(ngModel)]="selectAllCheckbox" [checked]="selectAllCheckbox"
                            (change)="selectAllMember($event)">Select All
                        </mat-checkbox>
                        <div class="table-responsive cardListTable" id="Data_table_scroll" data-simplebar
                            style="max-height: calc(100vh - 200px); min-height: 500px">
                            <table id="ListingCard" class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <span class="ms-4">Member</span>
                                        </th>
                                        <th>Email</th>
                                        <th class="text-center">Views</th>
                                        <th class="text-center">Leads</th>
                                        <th class="text-center">Devices</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cardListRow position-relative" *ngFor="let member of membersData">
                                        <td>
                                            <div class="d-inline-flex align-items-center ps-4 position-relative">
                                                <!-- <i class="icon dragIcon position-absolute">
                                                    <img src="../../../assets/images/drag_indicator_black_24dp_2_.svg" alt="">
                                                </i> -->
                                                <mat-checkbox style="z-index: 1; " [checked]="member.checked"
                                                    [(ngModel)]="member.checked"
                                                    (change)="singleSelectMember($event,member)"
                                                    class="custom_check ms-1"></mat-checkbox>
                                                <div class="position-relative wh60 ms-3 members-list-view">
                                                    <div
                                                        class="memberProfileImg boxShadow rounded-circle primaryBg d-flex align-items-center justify-content-center overflow-hidden wh60  position-relative">
                                                        <img *ngIf="member?.photo" class="fit_img"
                                                            [src]="imageurl +member?.photo" alt="">
                                                        <img *ngIf="!member?.photo" class=" w-50 h-50"
                                                            src="assets/images/memberProfile_icon.svg" alt="">

                                                    </div>
                                                    <!-- <div class="logo-wrap-list">
                                                        <img src="imageBaseUrl + member?.logo" alt="" class="fit_img">
                                                    </div> -->
                                                </div>
                                                <p *ngIf="member?.name"
                                                    class="m-0 text-truncate ms-3 me-2 fs13 fw-bold memberNameWidth">
                                                    {{member?.name}}</p>
                                                <p *ngIf="!member?.name"
                                                    class="m-0 text-truncate ms-3 me-2 fs13 fw-bold memberNameWidth">
                                                    {{member?.email}}</p>
                                                <span *ngIf="member.role==userInfo?.role"
                                                    class="fs12 fontMed text_light me-2">(me)</span>
                                                <div
                                                    class="badge rounded-pill bg_light text-uppercase fontMed fs9 text_light px-3 py-2">
                                                    {{member?.role}} </div>
                                                <img *ngIf="member?.lock_status==1"
                                                    src="assets/images/lock_black_24dp.svg" alt="">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-inline-flex align-items-center">
                                                <span class="text-truncate memberNameWidth">{{member?.email}}</span>
                                                <button
                                                    class="border-0  text-white whiteBg btn-sm rounded-pill px-3 py-2 lh-1 fs11 ms-5  fontMed resendInviteBtn"
                                                    type="button">Resend Invite</button>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <span>05</span>
                                        </td>
                                        <td class="text-center">
                                            <span>02</span>
                                        </td>

                                        <td class="text-center">
                                            <div class="d-inline-flex align-items-center">
                                                <span>11 </span>
                                                <div class="dropdown dotsDropdown" (click)="$event.stopPropagation()">
                                                    <button class="border-0 whiteBg btn-sm rounded-pill ps-3 pe-0 lh-1"
                                                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <img src="../../../assets/images/more_vert_black_24dp.svg"
                                                            alt="">
                                                    </button>
                                                    <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 dropdown-menu-end"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li *ngIf="member.lock_status=='0'"
                                                            (click)="lockMemberStatus(1,member.user_id)">
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/lock_black_24dp.svg" alt="">
                                                                </i>
                                                                <span>Lock member</span>
                                                            </a>
                                                        </li>
                                                        <li *ngIf="member.lock_status=='1'"
                                                            (click)="lockMemberStatus(0,member.user_id)"><a
                                                                class="dropdown-item fw-bold fs12"><i
                                                                    class="icon me-2"><img
                                                                        src="assets/images/lock_open_black_24dp.svg"
                                                                        alt=""></i>
                                                                <span>Unlock
                                                                    member</span></a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/file_download_black_24dp.svg"
                                                                        alt="">
                                                                </i>
                                                                <span>Download QR code</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/visibility_black_24dp.svg"
                                                                        alt="">
                                                                </i>
                                                                <span>View profile</span>
                                                            </a>
                                                        </li>
                                                        <li (click)="duplicatemember(member.user_id)">
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/content_copy_black_24dp.svg"
                                                                        alt="">
                                                                </i>
                                                                <span>Duplicate Member</span>
                                                            </a>
                                                        </li>

                                                        <li (click)="roleCheck('user',member.user_id)" *ngIf="member.role=='admin'">
                                                            <a class="dropdown-item d-flex align-items-center">
                                                                <i class="icon me-2">
                                                                    <img src="../../../assets/images/revoke-admin.svg"
                                                                        alt="">
                                                                </i>
                                                                <p class="m-0 fs12 fw-bold">Revoke admin status</p>
                                                            </a>
                                                        </li>

                                                        <li *ngIf="member.role=='user'" (click)="roleCheck('admin',member.user_id)"><a
                                                            class="dropdown-item fw-bold fs12"><i class="icon me-2"><img
                                                                src="assets/images/admin_panel_settings_black_24dp.svg" alt=""></i> <span>Make
                                                              admin</span></a>
                                                        </li>
                                                    </ul>
                                                    <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3 dropdown-menu-end"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/file_download_black_24dp.svg"
                                                                        alt="">
                                                                </i>
                                                                <span>Download QR code</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fw-bold fs12">
                                                                <i class="icon me-2">
                                                                    <img src="assets/images/visibility_black_24dp.svg"
                                                                        alt=""></i>
                                                                <span>View profile</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td colspan="10" class="dragPlaceholderDiv example-custom-placeholder"
                                            *cdkDragPlaceholder></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- fixed footer dropdown Start-->
                    <section class="footerMenu_sec" id="footerMenu" *ngIf="countMember>0">
                        <div class="footerMenu_container primaryBg px-4 position-fixed py-3">
                            <div class="d-flex">
                                <div class="colinner d-flex align-items-center">
                                    <p class="m-0 text-white fs12"><span>{{countMember}}</span> member selected</p>
                                </div>
                                <div class="colinner borLeftRight px-5 mx-5 d-flex">
                                    <div class="dropupContainer d-flex align-items-center justify-content-evenly">
                                        <!-- Links and Contact Info -->
                                        <div class="dropdown-center dropup">
                                            <p class="dropdown-toggle m-0 text-white fs12 cursor_pointer"
                                                data-bs-toggle="dropdown" aria-expanded="false"> Links and Contact Info
                                            </p>
                                            <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3">
                                                <li (click)="addLinksContact()">
                                                    <a class="dropdown-item fw-bold fs12 cursor_pointer" type="button">
                                                        <i class="icon me-2">
                                                            <svg id="add_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                                width="18.482" height="18.482"
                                                                viewBox="0 0 18.482 18.482">
                                                                <path id="Path_33" data-name="Path 33"
                                                                    d="M0,0H18.482V18.482H0Z" fill="none" />
                                                                <path id="Path_34" data-name="Path 34"
                                                                    d="M15.781,11.161h-4.62v4.62H9.62v-4.62H5V9.62H9.62V5h1.54V9.62h4.62Z"
                                                                    transform="translate(-1.15 -1.15)" fill="#c9c9c9" />
                                                            </svg>
                                                        </i>
                                                        <span>Add Links and Contact Info</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- Member -->
                                        <div class="dropdown-center dropup">
                                            <p class="dropdown-toggle m-0 text-white fs12 cursor_pointer"
                                                data-bs-toggle="dropdown" aria-expanded="false"> Member </p>
                                            <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3">
                                                <!-- List item -->
                                                <li (click)="customizeProfile()">
                                                    <a class="dropdown-item fw-bold fs12 cursor_pointer">
                                                        <i class="icon me-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13"
                                                                height="13" viewBox="0 0 13 13">
                                                                <g id="palette_black_24dp_2_"
                                                                    data-name="palette_black_24dp (2)"
                                                                    transform="translate(0.219 -0.138)">
                                                                    <g id="Group_1098" data-name="Group 1098"
                                                                        transform="translate(0 0)">
                                                                        <rect id="Rectangle_6324"
                                                                            data-name="Rectangle 6324" width="13"
                                                                            height="13"
                                                                            transform="translate(-0.219 0.138)"
                                                                            fill="none" />
                                                                    </g>
                                                                    <g id="Group_1102" data-name="Group 1102"
                                                                        transform="translate(-0.201 0.682)">
                                                                        <path id="Path_1239" data-name="Path 1239"
                                                                            d="M8.228,14.456A6.228,6.228,0,1,1,8.228,2c3.432,0,6.228,2.516,6.228,5.605a3.74,3.74,0,0,1-3.737,3.737h-1.1a.308.308,0,0,0-.311.311.329.329,0,0,0,.081.206,1.584,1.584,0,0,1,.4,1.04A1.558,1.558,0,0,1,8.228,14.456Zm0-11.21a4.982,4.982,0,1,0,0,9.965.308.308,0,0,0,.311-.311.337.337,0,0,0-.087-.218A1.553,1.553,0,0,1,9.617,10.1h1.1A2.491,2.491,0,0,0,13.21,7.605C13.21,5.2,10.975,3.246,8.228,3.246Z"
                                                                            transform="translate(-2 -2)"
                                                                            fill="#c9c9c9" />
                                                                        <ellipse id="Ellipse_105"
                                                                            data-name="Ellipse 105" cx="1.118"
                                                                            cy="0.559" rx="1.118" ry="0.559"
                                                                            transform="translate(1.863 4.982)"
                                                                            fill="#c9c9c9" />
                                                                        <ellipse id="Ellipse_106"
                                                                            data-name="Ellipse 106" cx="1.118"
                                                                            cy="0.559" rx="1.118" ry="0.559"
                                                                            transform="translate(4.099 2.746)"
                                                                            fill="#c9c9c9" />
                                                                        <circle id="Ellipse_107" data-name="Ellipse 107"
                                                                            cx="0.559" cy="0.559" r="0.559"
                                                                            transform="translate(7.453 2.746)"
                                                                            fill="#c9c9c9" />
                                                                        <ellipse id="Ellipse_108"
                                                                            data-name="Ellipse 108" cx="1.118"
                                                                            cy="0.559" rx="1.118" ry="0.559"
                                                                            transform="translate(8.571 4.982)"
                                                                            fill="#c9c9c9" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <span>Customize Profile Template</span>
                                                    </a>
                                                </li>
                                                <!-- List item -->
                                            </ul>
                                        </div>

                                        <!-- QR code -->
                                        <div class="dropdown-center dropup">
                                            <p class="dropdown-toggle m-0 text-white fs12 cursor_pointer"
                                                data-bs-toggle="dropdown" aria-expanded="false">QR code </p>
                                            <ul class="dropdown-menu border-0 boxShadow rounded-3 py-3">
                                                <!-- List item -->
                                                <li class="fileDisableupload cursor_pointer">
                                                    <a class="dropdown-item fw-bold fs12">
                                                        <input type="file" autofocus="false">
                                                        <i class="icon me-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13"
                                                                height="13" viewBox="0 0 13 13">
                                                                <g id="qr_code_black_24dp_4_"
                                                                    data-name="qr_code_black_24dp (4)"
                                                                    transform="translate(0.219 -0.182)">
                                                                    <g id="Group_1103" data-name="Group 1103"
                                                                        transform="translate(0 0)">
                                                                        <rect id="Rectangle_6325"
                                                                            data-name="Rectangle 6325" width="13"
                                                                            height="13"
                                                                            transform="translate(-0.219 0.182)"
                                                                            fill="none" />
                                                                    </g>
                                                                    <g id="Group_1105" data-name="Group 1105"
                                                                        transform="translate(1.206 1.239)">
                                                                        <g id="Group_1104" data-name="Group 1104">
                                                                            <path id="Path_1240" data-name="Path 1240"
                                                                                d="M3,7.456H7.456V3H3ZM4.114,4.114H6.342V6.342H4.114Z"
                                                                                transform="translate(-3 -3)"
                                                                                fill="#c9c9c9" />
                                                                            <path id="Path_1241" data-name="Path 1241"
                                                                                d="M3,17.456H7.456V13H3Zm1.114-3.342H6.342v2.228H4.114Z"
                                                                                transform="translate(-3 -7.43)"
                                                                                fill="#c9c9c9" />
                                                                            <path id="Path_1242" data-name="Path 1242"
                                                                                d="M13,3V7.456h4.456V3Zm3.342,3.342H14.114V4.114h2.228Z"
                                                                                transform="translate(-7.429 -3)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6326"
                                                                                data-name="Rectangle 6326" width="1"
                                                                                height="1"
                                                                                transform="translate(8.575 8.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6327"
                                                                                data-name="Rectangle 6327" width="1"
                                                                                height="1"
                                                                                transform="translate(5.575 4.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6328"
                                                                                data-name="Rectangle 6328" width="1"
                                                                                height="1"
                                                                                transform="translate(6.575 5.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6329"
                                                                                data-name="Rectangle 6329" width="1"
                                                                                height="2"
                                                                                transform="translate(5.575 6.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6330"
                                                                                data-name="Rectangle 6330" width="1"
                                                                                height="1"
                                                                                transform="translate(6.575 8.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6331"
                                                                                data-name="Rectangle 6331" width="1"
                                                                                height="2"
                                                                                transform="translate(7.575 6.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6332"
                                                                                data-name="Rectangle 6332" width="1"
                                                                                height="1"
                                                                                transform="translate(7.575 4.943)"
                                                                                fill="#c9c9c9" />
                                                                            <rect id="Rectangle_6333"
                                                                                data-name="Rectangle 6333" width="1"
                                                                                height="1"
                                                                                transform="translate(8.575 5.943)"
                                                                                fill="#c9c9c9" />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <span>Set QR code logo</span>
                                                    </a>
                                                </li>

                                                <!-- List item -->
                                                <li>
                                                    <a class="dropdown-item fw-bold fs12 cursor_pointer">
                                                        <i class="icon me-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13"
                                                                height="13" viewBox="0 0 13 13">
                                                                <g id="palette_black_24dp_2_"
                                                                    data-name="palette_black_24dp (2)"
                                                                    transform="translate(0.219 -0.138)">
                                                                    <g id="Group_1098" data-name="Group 1098"
                                                                        transform="translate(0 0)">
                                                                        <rect id="Rectangle_6324"
                                                                            data-name="Rectangle 6324" width="13"
                                                                            height="13"
                                                                            transform="translate(-0.219 0.138)"
                                                                            fill="none" />
                                                                    </g>
                                                                    <g id="Group_1102" data-name="Group 1102"
                                                                        transform="translate(1.114 0.682)">
                                                                        <g id="Group_1101" data-name="Group 1101">
                                                                            <g id="Group_1100" data-name="Group 1100">
                                                                                <g id="Group_1099"
                                                                                    data-name="Group 1099">
                                                                                    <path id="Path_1239"
                                                                                        data-name="Path 1239"
                                                                                        d="M7.571,13.141A5.57,5.57,0,1,1,7.571,2a5.323,5.323,0,0,1,5.571,5.013A3.345,3.345,0,0,1,9.8,10.356H8.813a.276.276,0,0,0-.279.279.294.294,0,0,0,.072.184,1.417,1.417,0,0,1,.357.93A1.393,1.393,0,0,1,7.571,13.141Zm0-10.027a4.456,4.456,0,1,0,0,8.913.276.276,0,0,0,.279-.279.3.3,0,0,0-.078-.195A1.389,1.389,0,0,1,8.813,9.242H9.8a2.228,2.228,0,0,0,2.228-2.228A4.211,4.211,0,0,0,7.571,3.114Z"
                                                                                        transform="translate(-2 -2)"
                                                                                        fill="#c9c9c9" />
                                                                                    <ellipse id="Ellipse_105"
                                                                                        data-name="Ellipse 105" cx="1"
                                                                                        cy="0.5" rx="1" ry="0.5"
                                                                                        transform="translate(1.667 4.456)"
                                                                                        fill="#c9c9c9" />
                                                                                    <ellipse id="Ellipse_106"
                                                                                        data-name="Ellipse 106" cx="1"
                                                                                        cy="0.5" rx="1" ry="0.5"
                                                                                        transform="translate(3.667 2.456)"
                                                                                        fill="#c9c9c9" />
                                                                                    <circle id="Ellipse_107"
                                                                                        data-name="Ellipse 107" cx="0.5"
                                                                                        cy="0.5" r="0.5"
                                                                                        transform="translate(6.667 2.456)"
                                                                                        fill="#c9c9c9" />
                                                                                    <ellipse id="Ellipse_108"
                                                                                        data-name="Ellipse 108" cx="1"
                                                                                        cy="0.5" rx="1" ry="0.5"
                                                                                        transform="translate(7.667 4.456)"
                                                                                        fill="#c9c9c9" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <span>Set QR code color</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="colinner d-flex ms-auto">
                                    <div class="dropupContainer d-flex">
                                        <button class="bg-transparent border-0">
                                            <svg id="close_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24">
                                                <path id="Path_31" data-name="Path 31" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Path_32" data-name="Path 32"
                                                    d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                                    fill="#cacaca" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    </div>