import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileTemplateComponent } from './profile-template/profile-template.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { SocialTemplateModule } from '../social-template/social-template.module';
import { ProfileComponent } from './profile.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BusinessTemplateModule } from '../business-template/business-template.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentComponent } from './content/content.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { CreateProfileComponent } from './create-profile/create-profile.component';


@NgModule({
  declarations: [
    ProfileComponent,
    ProfileTemplateComponent,
    QrCodeComponent,
    ContentComponent,
    CreateProfileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    ProfileRoutingModule,
    SocialTemplateModule,
    BusinessTemplateModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatExpansionModule
  ],
  exports: [ProfileComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileModule { }
