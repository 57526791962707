import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss']
})
export class EditLinkComponent {

  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<EditLinkComponent>) {}
  closeDialog() {
    this.dialogRef.close();
  }

  back(): void {
    this.dialogRef.close();
  }
}
