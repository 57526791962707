<!-- 
    ==========================================Social Template Page====================================
 -->
 <div class="main-wrap">
    <div style="background-image: url();" class="inner-sec">
        <ng-container>
            <div class="user-info bioClass">
                <div class="user-img" data-aos="zoom-in-up" data-aos-delay="500">
                    <!-- // edited dummy image from girldummy to bg dummy on 29 nov 2022 start -->
                    <img src="../../../assets/images/userdummywithbg-preview.svg">
                </div>
                <div class="user-details" data-aos="zoom-in-left" data-aos-delay="500">
                    <h3 class="mb_0 text-dark fw-600">Josh Hazlewood</h3>
                    <h4 class="mb_0"> Human Resources</h4>
                    <!-- <h5 class="mb_0" [style.color]="'#000000' +'!important'"></h5> -->
                    <p class="mb_0" [ngStyle]="{'color': '#9E9E9E'}">country</p>
                </div>
            </div>
            
            <div class="section share_sec" style="background: url();" data-aos="zoom-in-up" data-aos-delay="500">
                <div class="margin_center" data-aos="fade-up" data-aos-delay="900">
                    <ul class="social-media">
                        <ng-container  Class="soc.highlight==true?'heigh-lighted': soc.slug=='embedvideo' ? 'embedvideo-unhighlight':''">
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/apple-music.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Apple Music</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/behance.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Behance</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/call.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Phone</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/daviplata.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Daviplata</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/email.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Gmail</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/facebook.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Facebook</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/github.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Github</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/instagram.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Instagram</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/medium.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Medium</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/outlook.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Outlook</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/paypal.png"  class="">
                                    </span>
                                    <p class="text-truncate">Paypal</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/soundcloud.png"  class="">
                                    </span>
                                    <p class="text-truncate">Soundcloud</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/spotify.png"  class="">
                                    </span>
                                    <p class="text-truncate ">Spotify</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/tiktok.png"  class="">
                                    </span>
                                    <p class="text-truncate ">TikTok</p>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <span class="icon_wrap custom_icon ">
                                        <img alt="icon" src="../../../assets/images/icons/twitter.png"  class="">
                                    </span>
                                    <p class="text-truncate ">x</p>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </ng-container>
        <!-- ...............end............ -->
    </div>


</div>