import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-template',
  templateUrl: './profile-template.component.html',
  styleUrls: ['./profile-template.component.scss']
})
export class ProfileTemplateComponent {
  constructor(private router: Router){
    
  }
}
