import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-links',
  templateUrl: './add-links.component.html',
  styleUrls: ['./add-links.component.scss']
})
export class AddLinksComponent {

  constructor(private dialog:MatDialog,public dialogRef: MatDialogRef<AddLinksComponent>,){

  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddLinksComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
