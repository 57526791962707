import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddLinksComponent } from '../add-links/add-links.component';

@Component({
  selector: 'app-add-links-contact',
  templateUrl: './add-links-contact.component.html',
  styleUrls: ['./add-links-contact.component.scss']
})
export class AddLinksContactComponent {
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AddLinksContactComponent>) {

  }

  addLink() {
    this.dialog.open(AddLinksComponent, {
      panelClass: ['cmn_modal', 'modal_sm'],
      width: '450px',
      // height: '500px'
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
