<div class="dialog_head p-5 position-relative">
        <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center" (click)="closeDialog()">
      <img src="assets/images/modal-cross.svg" />
    </button>
    <!-- <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center" (click)="closeDialog()" >
      <img src="assets/images/modal-cross.svg" />
    </button> -->
    <div class="d-flex align-items-end justify-content-between flex-wrap">
      <div class="heading-sec">
        <h4 class="mb-2 text_secondary fw-bold lh">Duplicate {{userInfo?.first_name?userInfo?.first_name + userInfo?.last_name : userInfo?.email}}'s profile</h4>
        <p class="text_secondary small mb-1 pb-3">Enter the number of times you'd like to duplicate <strong>{{userInfo?.first_name?userInfo?.first_name +userInfo?.last_name : userInfo?.email}}</strong>
        profile and select which profiles to include in the duplicates. 
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3 w-100 gap-3">
        <div class="w-50">
            <input type="number" (keypress)="numberOnly($event)"  [min]="1" placeholder="Number of duplicates" class="form-control rounded-3 bg_light border-0 py-0 fs12 h46" [(ngModel)]="this.numberOfDuplicate" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="w-50 ms-auto">
            <button type="submit" class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h46 ms-1" (click)="duplicate()">Duplicate</button>
        </div>
      </div>
    </div>
  </div>
