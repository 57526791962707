import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
{ path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) }, 
{ path: 'change-password', loadChildren: () => import('./components/change-password/change-password-routing.module').then(m => m.ChangePasswordRoutingModule) }, 
{ path: 'signup', loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule) }, 
{ path: 'members', loadChildren: () => import('./components/members/members-routing.module').then(m => m.MembersRoutingModule) }, 
{ path: 'profile', loadChildren: () => import('./components/profile/profile-routing.module').then (m => m.ProfileRoutingModule)},
{ path: 'forgot-password', loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
{ path: '', redirectTo: '/login', pathMatch: 'full' },
{ path: 'add-members', loadChildren: () => import('./components/members/add-members/add-members.module').then(m => m.AddMembersModule) },  
{ path: '**', redirectTo: '/login' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
