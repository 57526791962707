<!-- 
    <div *ngIf="usersubscription === null" class="router_overlay z-9" (click)="showMessage()">
    </div> -->

<div class="midheader pb-3 d-flex align-items-center justify-content-between">
    <h6 class="mb-0 text_secondary fontMed d-none d-xl-block">QR code</h6>
    <button class="btn whiteBg border rounded-pill px-4 py-0 fs12 fontMed h36">
        <svg class="me-1" id="Group_1053" data-name="Group 1053" xmlns="http://www.w3.org/2000/svg" width="8.283"
            height="8.283" viewBox="0 0 8.283 8.283">
            <path id="Path_1179" data-name="Path 1179"
                d="M11.248,9.695v1.553H5.035V9.695H4v1.553a1.038,1.038,0,0,0,1.035,1.035h6.212a1.038,1.038,0,0,0,1.035-1.035V9.695ZM10.73,7.624,10,6.894,8.659,8.23V4H7.624V8.23L6.283,6.894l-.73.73,2.588,2.588Z"
                transform="translate(-4 -4)" fill="#4d4e58" />
        </svg>
        Download
    </button>
</div>
<div class="social_container expansion_container" data-simplebar>
    <form class="">
        <div class="bg_light rounded-4 main_card mb-5">
            <div class="d-flex align-items-center justify-content-between head lh-1">
                <h5 class="mb-0 text_gray fs12 fontMed me-2">Custom Hex:</h5>
                <p class="mb-0 fs12 text-black">#000000</p>
                <!-- <input id="callbtn" [(ngModel)]="color" [ngModelOptions]="{standalone: true}" type="text" style="padding: 6px;width: 100px;
                margin-left: 15px;"> -->
            </div>
            <div class="body-sec">
                <h5 class="text_gray fs12 fontMed mb-4 pb-2">Choose a Color</h5>
                <div class="colors_row d-flex flex-wrap align-items-center ps-1">
                    <!-- radio button  -->
                    <div class="radio_itm position-relative">
                        <input class="position-absolute h-100 w-100 opacity-0 cursor_pointer" type="radio"
                        name="qrCodeColor" id="radio">
                        <span class="inner-circle rounded-circle h-100 w-100 d-block" Style="background-color: #252634;"></span>
                        <span class="outer-circle rounded-circle" Style="border :2px solid; border-color:#252634"></span>
                    </div>

                    <div class="radio_itm position-relative">
                        <input class="position-absolute h-100 w-100 opacity-0 cursor_pointer" type="radio"
                        name="qrCodeColor" id="radio">
                        <span class="inner-circle rounded-circle h-100 w-100 d-block" Style="background-color: #6c61e1;"></span>
                        <span class="outer-circle rounded-circle" Style="border :2px solid; border-color:#6c61e1"></span>
                    </div>

                    <div class="radio_itm position-relative">
                        <input class="position-absolute h-100 w-100 opacity-0 cursor_pointer" type="radio"
                        name="qrCodeColor" id="radio">
                        <span class="inner-circle rounded-circle h-100 w-100 d-block" Style="background-color:#ac61e1;"></span>
                        <span class="outer-circle rounded-circle" Style="border :2px solid; border-color:#ac61e1"></span>
                    </div>
                    <div class="radio_itm position-relative">
                        <input class="position-absolute h-100 w-100 opacity-0 cursor_pointer" type="radio"
                        name="qrCodeColor" id="radio">
                        <span class="inner-circle rounded-circle h-100 w-100 d-block" Style="background-color:#6196e1;"></span>
                        <span class="outer-circle rounded-circle" Style="border :2px solid; border-color:#6196e1"></span>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="btns-sec d-flex align-items-center justify-content-end pt-4 px-1">
        <button class="btn whiteBg border rounded-pill px-4 py-0 fs12 fontMed h36 me-2">Cancel</button>
        <button type="submit" class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1">Save</button>
    </div>
</div>