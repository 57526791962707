import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  currentRoute: any = '';
  drawerToggle: boolean = false;
  activeDrawerText: string = 'content';
  constructor(private app: AppComponent,  private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

changeDrawerText(text: string) {
  this.activeDrawerText = text;
  this.drawerToggle = false;
}


  ngOnInit(){
    this.app.changeurl('showdrawer')
    this.router.navigate(['profile/content'])
  }

  showPhone() {
    if (this.currentRoute == '/profile/QR-code') {
      return false
    }
    else {
      return true
    }
  }
}
