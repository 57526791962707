import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { AddLinksContactComponent } from '../modals/add-links-contact/add-links-contact.component';
import { CustomizeProfileTemplateComponent } from './customize-profile-template/customize-profile-template.component';
import { ApiService } from 'src/app/common/api-service/api.service';
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { DuplicateMemberComponent } from '../modals/duplicate-member/duplicate-member.component';
declare const $: any;
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent {
  isGridView: boolean = true;
  isListView: boolean = false;
  membersData: any = []
  userInfo: any = []
  singleSelectMemberData: any = []
  selectAllMemberId: any = []
  userId: any = []
  imageurl = environment.imageUrl
  pendingArray: any = [];
  countMember: any = 0
  selectAllCheckbox: any = false
  name: any;
  isemailIntro: boolean = false
  isInstant: boolean = false
  islead: boolean = false
  checkMemberLength: any;
  constructor(private app: AppComponent, public dialog: MatDialog, private api: ApiService, private cdr: ChangeDetectorRef, private message: MessagehandlingService) {

  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '')
    this.app.changeurl('showdrawer')
    this.userTeamData();
    this.encryptCheck();
   
    this.selectAllMemberId = []
  }


  addLinksContact() {
    this.dialog.open(AddLinksContactComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  duplicatemember(user_id:any) {
    this.dialog.open(DuplicateMemberComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '600px',
      data:{block:user_id}
    })
    .afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          this.userTeamData()
        }, 1000);
      });
  }
  customizeProfile() {
    this.dialog.open(CustomizeProfileTemplateComponent, {
      panelClass: ['cmn_modal', 'modal_md'],
      width: '875px',
    });
  }

  userTeamData() {
    let form = new FormData()
    this.api.getMembers(form).subscribe((res: any) => {
      this.membersData = res?.items
      this.countMember = 0
    })
  }


  encryptCheck() {
   setTimeout(() => {
    this.checkMemberLength = 0;
    this.membersData.forEach((ele: any) => {
      if (ele.showcheck == true) {
        this.checkMemberLength = this.checkMemberLength + 1;
      }
    });
   }, 0);
  }

  singleSelectMember(event: any, data: any) {
    this.isemailIntro = false
    this.isInstant = false
    this.islead = false

    if (event.checked == true) {
      if (data.member_status != '4') {
        this.singleSelectMemberData.push(data.user_id)
        if (this.singleSelectMemberData.length == 1) {
          this.name = data.first_name + data.last_name
        }

        this.countMember = this.countMember + 1;
        if (this.countMember == this.membersData.length) {
          this.selectAllCheckbox = true;
        }
      }
    }
    else {
      this.countMember = this.countMember - 1;
      this.selectAllCheckbox = false;
      if (this.countMember != this.membersData.length) {
        this.singleSelectMemberData.forEach((element: any, index: number) => {
          if (element == data.user_id) {
            this.singleSelectMemberData.splice(index, 1)
          }
        });
      }
    }
    
    sessionStorage.setItem('userIdArray', this.singleSelectMemberData)
  }



  selectAllMember(event: any) {

    this.pendingArray = []
    this.selectAllMemberId = []
    this.singleSelectMemberData = []
    if (event.checked == true) {
      this.membersData.forEach((res: any, index: number) => {
        this.membersData[index].checked = true;
        if (res.member_status == '4') {
          this.pendingArray.push(res.user_id)

        }
        else {
          this.userId.push(res.user_id)
          this.singleSelectMemberData = this.userId
        }
        if (this.pendingArray.length) {
          this.countMember = this.membersData.length - this.pendingArray.length
        }
        else {
          this.countMember = this.membersData.length
        }
      });
      this.selectAllMemberId.push(this.userId)
      sessionStorage.setItem('userIdArray', this.selectAllMemberId)
    }
    else {
      this.membersData.forEach((res: any, index: number) => {
        this.membersData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.countMember == this.membersData.length) {
          this.userId = []
          this.singleSelectMemberData = []
        }
        this.countMember = 0;
      });
    }
  }

  deSelectAllMember() {
    let event = {
      checked: false
    }
    this.selectAllMember(event)
  }

  changeToggleView(event: any) {
    this.userTeamData()
    if (event == "GridView") {
      this.isGridView = true
      this.isListView = false
    }
    else {
      this.isListView = true
      this.isGridView = false;
      setTimeout(() => {
        $('#ListingCard').DataTable({
          bFilter: false,
          bPaginate: false,
          bLengthChange: false,
          bInfo: false,
          ordering: false,
        });
      }, 100);

    }
  }

  lockMemberStatus(key: any, data: any) {
    if (key instanceof Array && data) {
      let dt: any = {
        'lock_status': key.join(","),
        'user_id': data
      }
    }
    else if (key && data instanceof Array) {
      let dt: any = {
        'lock_status': key,
        'user_id': data.join(',')
      }
    }
    else {
      let dt: any = {
        'lock_status': key,
        'user_id': data
      }
      this.api.lockMemberStatus(dt).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 3000)
        setTimeout(() => {
          this.userTeamData()
        }, 100);
      })

    }

  }

  roleCheck(event: any, id: any) {
    let fd = new FormData
    fd.append('user_id', id)
    fd.append('role', event)
    this.api.updateMemberRole(fd).subscribe((res: any) => {
      if (res) {
        this.message.sucessMessage(res.msg, 2000)
        this.userTeamData()
      }
     
    })
  }  

}


