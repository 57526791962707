<h4 class="mb-3 pb-1 text_secondary d-none d-xl-block"><strong> Create Profile </strong></h4>
<ul class="nav nav-tabs cstm_tabs border-0 mb-4 bg_light rounded-pill" id="myTab" role="tablist">
    <li class="nav-item flex_1" role="presentation">
      <button class="nav-link active text_gray fs14 w-100 shadow-none rounded-pill" id="private-tab" data-bs-toggle="tab" data-bs-target="#private" type="button" role="tab" aria-controls="private" aria-selected="true">Private Profile</button>
    </li>
    <li class="nav-item flex_1" role="presentation">
      <button class="nav-link text_gray fs14 w-100 shadow-none rounded-pill" id="business-tab" data-bs-toggle="tab" data-bs-target="#business" type="button" role="tab" aria-controls="profile" aria-selected="false">Business Profile</button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">

    <div class="tab-pane fade show active" id="private" role="tabpanel" aria-labelledby="private-tab">
        <div class="social_container">
            <!-- Template 2 -->
            <form>
                <div class="row g-4">
                    <div class="col-md-4 col-lg-4">
                    <div class="logo-sec d-flex align-items-center flex-wrap mb-3 mt-4">
                        <div class="position-relative me-4 text-center d-flex justify-content-center flex-wrap">
                            <div class="logo-wrap cursor_pointer boxShadow overflow-hidden d-flex align-items-center primaryBg rounded100 justify-content-center">
                                <img class="img-fluid" src="assets/images/memberProfile_icon.svg">
                                <input type="file" class="d-none" formControlName="photo">
                            </div>
                            <h6 class="fs17 fw-bold mb-2 pb-1 lh-1 pt-3">Upload Profile Photo</h6>
                            <div class="upload-photo">
                                <img class="mb-1" src="assets/images/camera.svg">
                            </div>
                        </div>
                    </div>
                   </div>
                        <div class="col-lg-8 col-md-8 mt-5">
                           <div class="col-md-12 mb-3">
                             <div class="px-1">
                                <label class="w-100 fs14 lh-1 mb-2">Name</label>
                                <input type="text" class="form-control rounded-3 bg_light border-0 py-0 fs12 h46" placeholder="First Name">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="px-1">
                               <label class="w-100 fs14 lh-1 mb-2">Description</label>
                               <textarea class="form-control rounded-3 bg_light border-0 py-3 fs12 modalHeight" id="exampleFormControlTextarea1" rows="3"></textarea>
                           </div>
                         </div>
                        </div>
                </div>
            </form>
        </div>
        <div class="btns-sec d-flex align-items-center justify-content-end pt-4 px-1">
            <button type="submit" class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1">Save</button>
        </div>
    </div>
    <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
        <div class="social_container">
            <!-- Template 2 -->
            <form>
                <div class="row g-4">
                    <div class="col-md-4 col-lg-4">
                    <div class="logo-sec d-flex align-items-center flex-wrap mb-3 mt-4">
                        <div class="position-relative me-4 text-center d-flex justify-content-center flex-wrap">
                            <div class="logo-wrap cursor_pointer boxShadow overflow-hidden d-flex align-items-center primaryBg rounded100 justify-content-center">
                                <img class="img-fluid" src="assets/images/pod-logo-small.svg">
                                <input type="file" class="d-none" formControlName="photo">
                            </div>
                            <h6 class="fs17 fw-bold mb-2 pb-1 lh-1 pt-3">Upload Company Logo</h6>
                            <div class="upload-photo">
                                <img class="mb-1" src="assets/images/camera.svg">
                            </div>
                        </div>
                    </div>
                   </div>
                        <div class="col-lg-8 col-md-8 mt-5">
                           <div class="col-md-12 mb-3">
                             <div class="px-1">
                                <label class="w-100 fs14 lh-1 mb-2">Business Name</label>
                                <input type="text" class="form-control rounded-3 bg_light border-0 py-0 fs12 h46" placeholder="Business Name">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="px-1">
                               <label class="w-100 fs14 lh-1 mb-2">Description</label>
                               <textarea class="form-control rounded-3 bg_light border-0 py-3 fs12 modalHeight" id="exampleFormControlTextarea1" rows="3"></textarea>
                           </div>
                         </div>
                        </div>
                </div>
            </form>
        </div>
        <div class="btns-sec d-flex align-items-center justify-content-end pt-4 px-1">
            <button type="submit" class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1">Save</button>
        </div>
    </div>
  </div>
