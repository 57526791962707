<div class="layout-wrapper position-relative">
  <!-- <button [routerLink]="['/inviteAccepted']" style="margin-left:50rem">invite</button> -->
  <div class="mainContent">
    <div class="mainContentInner pb-5 mb-3">
      <div class="container-fluid half_bg position-relative">
        <div class="row min-vh-93 align-items-center">
          <!-- Dashboard screenshot section -->
          <div class="col-12 col-md-7 order-md-2 order-1">
            <div class="right_column d-flex justify-content-center flex-column align-items-center">
              <div class="right_container">
                <div class=" mt-4">
                  <img src="../../../assets/images/change-password.svg" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-5 order-md-1 order-2">
            <div class="left_column">
              <div class="logoSec d-flex align-items-center">
                <h5 class="m-0  fontMed">Change password</h5>
              </div>
              <p class="fs14 mt-2 mb-0">Your new password must be different from previous used passwords.</p>

              <div class="bsForm mt-4 fontMed mt-5">
                <form class="row g-4" [formGroup]="changePassword">
                  <div class="col-12">
                    <label class="form-label small">Old Password</label>
                    <div class="input_withEye position-relative">
                      <input class="form-control" [type]="opasswordType" (keydown)="checkEvent($event)"
                        placeholder="Old password" formControlName="old_password">
                      <span class="pass-icon" (click)="hidesShowOldpassword()"><img src="{{opasswordIcon}}">
                      </span>
                    </div>
                    <div
                      *ngIf="!changePassword.controls?.['old_password']?.valid && changePassword.controls?.['old_password']?.dirty"
                      class="error_main_div">
                      <div *ngIf="changePassword.controls?.['old_password']?.hasError('required')"
                        class="error-message">This
                        field is mandatory !</div>
                    </div>
                    <div *ngIf="changePassword.controls?.['old_password']?.hasError('leadingTrailingSpaces')"
                      class="error-message">
                      Space are not allowed.
                    </div>
                  </div>
                  <!-- </div> -->

                  <div class="col-12">
                    <label class="form-label small">New Password</label>
                    <div class="input_withEye position-relative">
                      <input class="form-control" [type]="npasswordType" (keydown)="checkEvent($event)"
                        placeholder="New password" formControlName="new_password">
                      <span class="pass-icon" (click)="hidesShowNewpassword()"><img src="{{npasswordIcon}}">
                      </span>
                    </div>
                    <div
                      *ngIf="!changePassword.controls?.['new_password']?.valid && changePassword.controls?.['new_password']?.dirty"
                      class="error_main_div">
                      <div *ngIf="changePassword.controls?.['new_password']?.hasError('required')"
                        class="error-message">This
                        field is mandatory !</div>
                      <div *ngIf="changePassword.controls?.['new_password']?.hasError('pattern')" class="error-message">
                        Password must
                        contain uppercase, lowercase, number and special characters and min length should be 8.
                      </div>
                  
                    <div *ngIf="changePassword.controls?.['new_password']?.hasError('leadingTrailingSpaces')"
                      class="error-message">
                      Space are not allowed.
                    </div>
                  </div>
                  </div>

                  <div class="col-12">
                    <label class="form-label small">Confirm Password</label>
                    <div class="input_withEye position-relative">
                      <input class="form-control" [type]="cpasswordType" (keydown)="checkEvent($event)"
                        placeholder="Confirm password" formControlName="confirm_password">
                      <span class="pass-icon" (click)="hidesShowConfirmpassword()"><img src="{{cpasswordIcon}}">
                      </span>
                    </div>
                    <div
                      *ngIf="!changePassword.controls?.['confirm_password']?.valid && changePassword.controls?.['confirm_password']?.dirty"
                      class="error_main_div">
                      <div
                        *ngIf="changePassword.controls?.['confirm_password']?.invalid && changePassword.controls?.['confirm_password']?.errors?.['ConfirmPassword']"
                        class="error-message">Password and confirm password is not match !</div>
                      <div *ngIf="changePassword.controls?.['confirm_password']?.hasError('required')"
                        class="error-message">This field
                        is Mandatory !</div>
                      <div *ngIf="changePassword.controls?.['confirm_password']?.hasError('leadingTrailingSpaces')"
                        class="error-message">
                        Space are not allowed.
                      </div>
                    </div>

                  </div>
                  <div class="col-12 mt-5">
                    <button type="submit" class="btn btn-primary btn_xl rounded-pill w-100 commonBtn"
                      (click)="changePasswordSubmit()">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>