import { Component } from '@angular/core';

@Component({
  selector: 'app-social-template',
  templateUrl: './social-template.component.html',
  styleUrls: ['./social-template.component.scss']
})
export class SocialTemplateComponent {

}
