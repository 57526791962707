import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersRoutingModule } from './members-routing.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomizeProfileTemplateComponent } from './customize-profile-template/customize-profile-template.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddMembersComponent } from './add-members/add-members.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    // CustomizeProfileTemplateComponent
  
    // AddMembersComponent
  ],
  imports: [
    CommonModule,
    MembersRoutingModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MembersRoutingModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,

  ]
})
export class MembersModule { }
