<div class="midheader pb-3">
    <h6 class="mb-3 pb-1 text_secondary fontMed d-none d-xl-block">Profile Template</h6>
    <select class="form-select h46 fs14 rounded-3 bg_light border-0 shadow-none">
        <option selected value="social_template">Private Template</option>
        <option value="business_template">Business Template</option>
    </select>
</div>

<div class="social_container" data-simplebar>
    <!-- Template 2 -->
    <form>
        <div class="mb-4">
            <div class="logo-sec d-flex align-items-center flex-wrap  mb-3">
                <div class="position-relative me-4">
                    <h6 class="fs17 fw-bold mb-2 pb-1 lh-1 pt-2">Profile Photo</h6>
                    <div (click)="openphoto.click()" class="logo-wrap cursor_pointer boxShadow overflow-hidden d-flex align-items-center primaryBg rounded100 justify-content-center ms-1">
                        <img class="img-fluid" src="assets/images/memberProfile_icon.svg">
                        <input #openphoto type="file" class="d-none" formControlName="photo">
                    </div>
                    <div class="upload-photo">
                        <img class="mb-1" src="assets/images/camera.svg">
                    </div>
                </div>
            </div>
            
            <div class="row g-4">
                <div class="col-lg-6">
                    <div class="px-1">
                        <label class="w-100 fs14 lh-1 mb-2">First Name</label>
                        <input type="text" formcontrolname="firstName" class="form-control rounded-3 bg_light border-0 py-0 fs12 h46 ng-pristine ng-valid ng-touched" placeholder="First Name">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="px-1">
                        <label class="w-100 fs14 lh-1 mb-2">Last Name</label>
                        <input type="text" formcontrolname="firstName" class="form-control rounded-3 bg_light border-0 py-0 fs12 h46 ng-pristine ng-valid ng-touched" placeholder="Last Name">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bg_light rounded-4 itm_box mb-4">
            <div class="">
                <label class="form-label fs14 text_gray">Color</label>
                <select class="form-select h32 fs12 rounded-3 cstmselect text_gray border-0 boxShadow"
                    formControlName="business_btn_color">
                    <option value="navy">Navy Blue</option>
                    <option selected value="white">White</option>
                </select>
            </div>
        </div>

        <h6 class="fs17 fw-bold mb-2 pb-1 ps-2 ms-1 lh-1">Apps/Icons</h6>
        <div class="bg_light rounded-4 itm_box">
            <div class="">
                <label class="form-label fs14 text_gray">Style</label>
                <select class="form-select h32 fs12 rounded-3 cstmselect text_gray border-0 boxShadow" formControlName="business_icon_style">
                    <option selected value="rectangular">Rectangular</option>
                </select>
            </div>
        </div>
    </form>
</div>

<div class="btns-sec d-flex align-items-center justify-content-end pt-4 px-1">
    <button type="reset" class="btn whiteBg border rounded-pill px-4 py-0 fs12 fontMed px-4 h36 me-2">Cancel</button>
    <button type="submit" class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-4 h36 ms-1">Save</button>
</div>