<div class="customizeTemplateContainer">
    <!-- back arrow button -->
    <div class="backarrow cursor_pointer p-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="5.677" height="10.112" viewBox="0 0 5.677 10.112">
            <g id="_4782742811535351484" data-name="4782742811535351484" transform="translate(38.792 -6.15) rotate(90)">
                <path id="Path_25" data-name="Path 25" d="M15.913,33.5a.34.34,0,0,0-.48,0L11.21,37.73,6.98,33.5a.34.34,0,0,0-.48.48l4.463,4.463a.332.332,0,0,0,.24.1.346.346,0,0,0,.24-.1L15.9,33.98a.333.333,0,0,0,.008-.48Z" transform="translate(0)" fill="#4d4e58" stroke="#4d4e58" stroke-width="0.5" />
            </g>
        </svg>
        <span class="fs13 ps-2">Back</span>
    </div>
    <mat-dialog-content class="p-5 pt-0 m-0" data-simplebar>
        <div class="row">
            <div class="col-md-7">
                <div class="templateFormContainer">
                    <div class="midheader pb-3">
                        <p class="mb-3 pb-1 text_secondary fontMed d-none d-xl-block">Customize theme on live card for 1 members</p>
                        <h6 class="mb-3 pb-1 text_secondary fontMed d-none d-xl-block">Profile Template</h6>
                        <select class="form-select h46 fs14 rounded-3 bg_light border-0 shadow-none" >
                            <option selected value="social_template">Private Template</option>
                            <option value="business_template">Business Template</option>
                        </select>
                    </div>

                    <div class="social_container" data-simplebar>
                        <!-- social template -->
                        <h5 class="mb-4 fw-normal fs21 pt-2 mt-1">Template Settings</h5>
                        <!-- Template 2 -->
                        <form class="">
                            <div class="mb-4">
                                <div class="logo-sec d-flex align-items-center flex-wrap  mb-3 mt-4">
                                    <div class="position-relative me-4">
                                        <h6 class="fs17 fw-bold mb-2 pb-1 lh-1 pt-2 ">Profile Photo</h6>
                                        <div (click)="openphoto.click()" class="logo-wrap cursor_pointer boxShadow overflow-hidden d-flex align-items-center primaryBg rounded100 justify-content-center ms-1">
                                            <img class="img-fluid"  src="assets/images/memberProfile_icon.svg">
                                            <input #openphoto type="file" class="d-none" formControlName="photo">
                                        </div>
                                        <div class="upload-photo">
                                            <img class="mb-1" src="assets/images/camera.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="fs17 fw-bold mb-2 pb-1 ps-2 ms-1 lh-1">Quick Access Buttons</h6>
                            <div class="bg_light rounded-4 itm_box mb-4">
                                <div class="">
                                    <label class="form-label fs14 text_gray">Color</label>
                                    <select class="form-select h32 fs12 rounded-3 cstmselect text_gray border-0 boxShadow"
                                        formControlName="business_btn_color">
                                        <option value="navy">Navy Blue</option>
                                        <option selected value="white">White</option>
                                    </select>
                                </div>
                            </div>

                            <h6 class="fs17 fw-bold mb-2 pb-1 ps-2 ms-1 lh-1">Apps/Icons</h6>
                            <div class="bg_light rounded-4 itm_box">
                                <div class="">
                                    <label class="form-label fs14 text_gray">Style</label>
                                    <select class="form-select h32 fs12 rounded-3 cstmselect text_gray border-0 boxShadow" formControlName="business_icon_style">
                                        <option selected value="rectangular">Rectangular</option>
                                        <option value="Rounded">Rounded</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Mobile Live preview template section -->
            <div class="col-md-5">
                <div class="profileContainerMobile">
                    <div class="livepreview d-flex align-items-center flex-column justify-content-center h-100 p-4">
                        <h6 class="fs12 mb-4">Personal profile live preview</h6>
                        <div class="phone_wrap" data-simplebar>
                            <div class="phonePreview position-relative"
                                style="background:url(../../../../assets/images/phone_img.png) no-repeat center;">
                                <div class="preview_container"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="btns-sec d-flex align-items-center justify-content-start pt-4 pb-4 px-5">
        <button class="btn whiteBg border rounded-pill px-4 py-0 fs12 fontMed px-4 h36 me-2" (click)="closeDialog()">Cancel</button>
        <button class="btn btn-primary commonBtn shadow-none rounded-pill px-5 py-0 fs12 fontMed px-2 h36 ms-1">Update 1 profiles</button>
    </div>
</div>