import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-customize-profile-template',
  templateUrl: './customize-profile-template.component.html',
  styleUrls: ['./customize-profile-template.component.scss'],
})
export class CustomizeProfileTemplateComponent {
  constructor(private dialog: MatDialog) {}
  closeDialog() {
    this.dialog.closeAll();
  }
}
