import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
@Component({
  selector: 'app-duplicate-member',
  templateUrl: './duplicate-member.component.html',
  styleUrls: ['./duplicate-member.component.scss']
})
export class DuplicateMemberComponent {
  userInfo: any = []
  numberOfDuplicate: any = ''
  constructor(public dialog: MatDialog, private message: MessagehandlingService, public dialogRef: MatDialogRef<DuplicateMemberComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private api: ApiService) {

  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '')
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  duplicate() {
    if (this.numberOfDuplicate==0||this.numberOfDuplicate==''  ) {
      this.message.errorMessage('Please enter any number', 1000)
      return
    }
    if (this.numberOfDuplicate > 10) {
      this.message.errorMessage('You can not copy more than 10 profiles.', 2000)
      return
    }
    let fd = new FormData
    fd.append('user_id', this.data.block)
    fd.append('count', this.numberOfDuplicate ? this.numberOfDuplicate : '1')
    this.api.copyAccount(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.msg, 1000)
      this.dialogRef.close()

    })
  }
}
