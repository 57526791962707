<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button (click)="back()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <h5 class="mb-1 mt-3 text-center text_secondary fw-bold lh-1">Call link</h5>
    <!-- delete button -->
    <button class="btn deleteBtn border-0"><img
            src="assets/images/deleteicn.svg" alt=""></button>
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap">
                <img src="../../../../assets/images/icons/call.png" class="imgCls">
            </div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">Call</p>
        </div>
        
        <div class="padding-content pt-0">
            <!-- form content -->
            <form>
                <div class="inputwrap position-relative mb-3 mt-3">
                    <input type="text" formControlName="content" class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed pe-5" placeholder="Enter number">
                </div>
   
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Title</label>
                    <input formControlName="title" class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none" >
                </div>
                <button type="submit" class="btn  btn-primary commonBtn rounded-pill fs17 w-100  shadow-none">Update link</button>
            </form>
        </div>
        <!-- </form>  -->
        <!-- </div> -->
    </div>
</mat-dialog-content>
