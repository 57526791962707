import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { ProfileTemplateComponent } from './profile-template/profile-template.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { ContentComponent } from './content/content.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';

const routes: Routes = [{ path: '', component: ProfileComponent,
children:[
  {path: 'profile-template', component:ProfileTemplateComponent},
  {path: 'QR-code', component:QrCodeComponent},
  {path: 'content', component:ContentComponent},
  {path: 'create', component:CreateProfileComponent},
  
]
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
