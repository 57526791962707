import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})


export class ChangePasswordComponent {
  userInfo: any = []
  changePassword!: FormGroup;
  opasswordType: any = "password"
  opasswordIcon: any = "../../../../assets/images/eye-slash.svg";
  cpasswordType: any = "password";
  cpasswordIcon: any = "../../../../assets/images/eye-slash.svg";
  npasswordType: any = "password";
  npasswordIcon: any = "../../../../assets/images/eye-slash.svg";
  constructor(private app: AppComponent, private formBuilder: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.changePassword = this.formBuilder.group({
      old_password: ['', [Validators.required, this.noLeadingTrailingSpaces()]],
      confirm_password: ['', [Validators.required, this.noLeadingTrailingSpaces()]],
      new_password: ['', [Validators.required,Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"), this.noLeadingTrailingSpaces()]],
    }, { validator: this.checkPasswords });
  }



  hidesShowOldpassword() {
    this.opasswordType = this.opasswordType === 'text' ? 'password' : 'text';
    this.opasswordIcon = this.opasswordIcon === '../../../../assets/images/eye-slash.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/eye-slash.svg';
  }

  hidesShowConfirmpassword() {
    this.cpasswordType = this.cpasswordType === 'text' ? 'password' : 'text';
    this.cpasswordIcon = this.cpasswordIcon === '../../../../assets/images/eye-slash.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/eye-slash.svg';
  }

  hidesShowNewpassword() {
    this.npasswordType = this.npasswordType === 'text' ? 'password' : 'text';
    this.npasswordIcon = this.npasswordIcon === '../../../../assets/images/eye-slash.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/eye-slash.svg';
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.app.changeurl('showdrawer')

  }



  changePasswordSubmit() {
    if (this.changePassword.invalid) {
      this.message.errorMessage('Please fill all fields', 2000)
    } else {
      let data = new FormData
      data.append('old_password', this.changePassword.value.old_password)
      data.append('new_password', this.changePassword.value.new_password)
      data.append('confirm_password', this.changePassword.value.confirm_password)
      this.api.changePassword(data).subscribe((res: any) => {
        if (res.success == 1) {
          this.changePassword.reset()
          this.message.sucessMessage(res.msg, 2000)
        }
        else {
          this.message.errorMessage(res.msg, 2000)
        }
      })
    }
  }


  checkPasswords(control: FormControl) {
    const pass = control.get('new_password')?.value
    const confirmPass = control.get('confirm_password')?.value
    if (pass != confirmPass) {
      control.get('confirm_password')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string' && control.value.trim() !== control.value) {
        return { leadingTrailingSpaces: true };
      }
      return null;
    };
  }

}
