import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessTemplateComponent } from './business-template.component';
import { RouterModule } from '@angular/router';
import { ProfileRoutingModule } from '../profile/profile-routing.module';



@NgModule({
  declarations: [BusinessTemplateComponent],
  imports: [
    CommonModule,
    RouterModule,
    ProfileRoutingModule

    
  ],
  exports: [BusinessTemplateComponent]
})
export class BusinessTemplateModule { }
