<div class="midheader pb-3">
    <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-2 pb-1 text_secondary fw-bold lh-1">Add content</h4>
        <div class="">
            <button (click)="addContent()"
                class="btn btn-primary rounded-pill commonBtn p-0 wh41" type="button">
                <svg id="add_black_24dp" xmlns="http://www.w3.org/2000/svg" width="23.709" height="23.709" viewBox="0 0 23.709 23.709">
                    <path id="Path_33" data-name="Path 33" d="M0,0H23.709V23.709H0Z" fill="none" />
                    <path id="Path_34" data-name="Path 34" d="M18.83,12.9H12.9V18.83H10.927V12.9H5V10.927h5.927V5H12.9v5.927H18.83Z" transform="translate(-0.061 -0.061)" fill="#fff" />
                </svg>
            </button>
        </div>
    </div>
</div>

<ng-container>
    <div class="social_container" data-simplebar>
        <ng-container>
            <div class="socialAccordian">
                <mat-accordion class="customAccordion" [multi]="true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Contact Information</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4 cursor_pointer"  (click)="editLink()">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/call.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Phone</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/whatsapp.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Whatsapp</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/email.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Gmail</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/outlook.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Outlook</p>
                            </div>
                        </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Social Media</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/facebook.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Facebook</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/instagram.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Instagram</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/linkedin.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Linkedin</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/twitter.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">x</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/tiktok.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">TikTok</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/youtube.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Youtube</p>
                            </div>
                        </div>

                    </mat-expansion-panel>
                    
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Portfolio</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/github.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Github</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/behance.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Behance</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/medium.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Medium</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/soundcloud.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">SoundCloud</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/spotify.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Spotify</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/apple-music.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Apple Music</p>
                            </div>
                        </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Finance</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/paypal.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Paypal</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/daviplata.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Daviplata</p>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Business</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/google-map.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Google map</p>
                            </div>

                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/tripadvisor.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Tripadvisor</p>
                            </div>
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/yelp.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Yelp</p>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="accordionShadow">
                            <mat-panel-title class="fs17 text_gray fw-normal">Other</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="socialList d-flex flex-wrap colgap">
                            <div class="socialBox d-flex flex-column align-items-center p-2 socialIconBgLight rounded-4">
                                <div class="socialImg rounded mt-2">
                                    <img class="img-fluid content_icon" src="../../../../assets/images/icons/Link.png">
                                 </div>
                                <p class="mb-0 fs12 mt-2 text_secondary">Link</p>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-container>
    </div>
</ng-container>

