import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessagehandlingService } from '../message/messagehandling.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient, public router: Router, private message: MessagehandlingService) { }


  signup(body: any) {
    return this.http.post(this.baseUrl + '/team/register', body)
  }

  login(body: any) {
    return this.http.post(this.baseUrl + '/team/login', body)
  }

  forgotPassword(body: any) {
    return this.http.post(this.baseUrl + '/api/forgot_password', body)
  }

  resetPinPassword(body: any) {
    return this.http.post(this.baseUrl + `/api/reset_pin_authenticate`, body)
  }

  resetPassword(body: any) {
    return this.http.post(this.baseUrl + `/api/reset_password`, body)
  }

  sendInvitation(body: any) {
    return this.http.post(this.baseUrl + `/team/send_invitation_email`, body)
  }

  getMembers(body: any) {
    return this.http.post(this.baseUrl + `/team/get_user_teams`, body)
  }

  lockMemberStatus(body: any) {
    return this.http.post(this.baseUrl + `/team/update_lock_status`, body)
  }

  updateMemberRole(body: any) {
    return this.http.post(this.baseUrl + `/team/update_user_role`, body)
  }

  changePassword(body: any) {
    return this.http.post(this.baseUrl + `/api/change_password`, body)
  }

  copyAccount(body: any) {
    return this.http.post(this.baseUrl + `/team/copy_account`, body)
  }


}
