
<div class="dialog_head p-5 position-relative">
  <button class="btn p-1 border-0 modal-close d-flex align-items-center justify-content-center" (click)="closeDialog()" >
    <img src="assets/images/modal-cross.svg" />
  </button>
  <div class="d-flex align-items-end justify-content-between flex-wrap">
    <div class="heading-sec">
      <h3 class="mb-2 pb-1 text_secondary fw-bold lh-1">Add content</h3>
      <p class="text_secondary small mb-1">Select from our wide variety of links and contact info below.</p>
    </div>
    <!-- header Search box -->
    <div class="position-relative searchbox me-3">
        <input type="text" class="form-control rounded-5 border-0 inputH42 bg_light" placeholder="Search content…" autocomplete="off"/>
        <span class="searchIcon position-absolute">
            <img src="assets/images/search_icon.svg"/>
        </span>
    </div>
  </div>
</div>
<mat-dialog-content class="p-5 pt-0 m-0" data-simplebar>
  <div class="content_sec">
    <div>
      <h4 class="text_secondary fs17 fontMed mb-4 lh-1 mt-4">Contact Information</h4>
      <div class="row g-3">
          <div class="col-md-6 col-lg-4">
            <div class="content-item bg_light d-flex align-items-center">
              <div class="icon-wrap me-3 flex-shrink-0">
                <img src="../../../../assets/images/icons/call.png" />
              </div>
              <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                <p class="small fontMed text-black m-0">Phone</p>
                <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="content-item bg_light d-flex align-items-center">
              <div class="icon-wrap me-3 flex-shrink-0">
                <img src="../../../../assets/images/icons/whatsapp.png" />
              </div>
              <div class="text-sec flex_1 d-flex align-items-center justify-content-between" >
                <p class="small fontMed text-black m-0">Whatsapp</p>
                <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="content-item bg_light d-flex align-items-center">
              <div class="icon-wrap me-3 flex-shrink-0">
                <img src="../../../../assets/images/icons/email.png"/>
              </div>
              <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                <p class="small fontMed text-black m-0">Gmail</p>
                <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="content-item bg_light d-flex align-items-center">
              <div class="icon-wrap me-3 flex-shrink-0">
                <img src="../../../../assets/images/icons/outlook.png" />
              </div>
              <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                <p class="small fontMed text-black m-0">Outlook</p>
                <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
              </div>
            </div>
          </div>

        <h4 class="text_secondary fs17 fontMed mb-0 lh-1">Social Media</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/facebook.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Facebook</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/instagram.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Instagram</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black"
                            (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/linkedin.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Linkedin</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/twitter.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">x</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/tiktok.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">TikTok</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/youtube.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Youtube</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

        </div>

        <h4 class="text_secondary fs17 fontMed mb-0 lh-1">Portfolio</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/github.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Github</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/behance.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Behance</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/medium.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Medium</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/soundcloud.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">SoundCloud</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/spotify.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Spotify</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/apple-music.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Apple Music</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="text_secondary fs17 fontMed mb-0 lh-1">Finance</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/paypal.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Paypal</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/daviplata.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Daviplata</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="text_secondary fs17 fontMed mb-0 lh-1">Business</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/google-map.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Google map</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/tripadvisor.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Tripadvisor</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/yelp.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Yelp</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="text_secondary fs17 fontMed mb-0 lh-1">Other</h4>
        <div class="row g-3">
            <div class="col-md-6 col-lg-4">
                <div class="content-item bg_light d-flex align-items-center">
                    <div class="icon-wrap me-3 flex-shrink-0">
                        <img src="../../../../assets/images/icons/Link.png">
                    </div>
                    <div class="text-sec flex_1 d-flex align-items-center justify-content-between">
                        <p class="small fontMed text-black m-0">Link</p>
                        <button class="btn p-0 addbtn boxShadow border-0 fs12 fontMed text-black" (click)="addLink()">Add</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
