<aside class="sidebarNav">
    <!-- LOGO -->
    <div class="navbar-brand text-center">
        <img src="assets/images/logo_login.png" alt="">
    </div>
    <!-- Sidebar menu list -->
    <div class="sidebarMenu_container pt-2" data-simplebar>
        <div class="sidebar_inner">
            <ul class="sidebar list-unstyled fs12 fontMed m-0">
                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/members']" [routerLinkActive]="'active'">
                    <i class="icon">
                        <img src="assets/images/menu-person_black_24dp.svg" />
                    </i>
                    <span>Members</span>
                </li>
            </ul>

            <ul class="sidebar list-unstyled fs12 fontMed m-0">
                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/change-password']" [routerLinkActive]="'active'">
                    <i class="icon">
                        <img src="assets/images/menu-settings_black_24dp.svg" />
                    </i>
                    <span>Settings</span>
                </li>

                <!-- item -->
                <li class="listItem d-flex align-items-center user_img" >
                    <i class="icon_user rounded-pill d-flex align-items-center justify-content-center">
                        <img *ngIf="userInfo?.photo" class="icon_user rounded-pill" [src]="imageUrl + userInfo.photo"
                        alt="">

                    <img *ngIf="!userInfo?.photo" class="rounded-pill" src="../../assets/images/user_icon.svg"
                        alt=""></i>
                    <div class="user_info text-truncate ms-1">
                        <span>{{userInfo?.email}}</span>
                        <p class="m-0 text_light fs11 text-uppercase">{{userInfo?.role}}</p>
                    </div>
                </li>

            </ul>
            <ul class="list-unstyled sidebar list-unstyled fs13 fontMed mb-auto mt-3">
                <li class="listItem d-flex align-items-center" (click)="logOut()"
                    [routerLinkActive]="['active']">
                    <i class="icon"><img src="assets/images/user_icon.svg" /></i>
                    <span>Logout</span>
                </li>
            </ul>
        </div>
    </div>
</aside>